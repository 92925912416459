import React, { FC } from 'react';
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import useAlert from 'hooks/useAlert';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { useUsersQuery } from 'queries/users';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useCardsMutation } from 'queries/cards';
import { TopupCard } from 'model/cards';
import { useParams } from 'react-router-dom';

type TopupProps = {
  onSubmitSuccess?: () => void;
};

const CARD_API_FEE = 0.04;

export const Topup: FC<TopupProps> = ({ onSubmitSuccess }) => {
  const { alert } = useAlert();
  const { id } = useParams();
  const { data: currentUser } = useUsersQuery();
  const { topupCard } = useCardsMutation();

  const schema = yup
    .object({
      amount: yup
        .number()
        .required()
        .min(10)
        .max(currentUser?.free_balance ?? +Infinity),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<any>({
    defaultValues: {
      amount: null,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const formAmount = watch('amount');

  const onSuccess = () => {
    alert('Card topup requested successfully.', 'success');
    reset();
    onSubmitSuccess?.();
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = (data: TopupCard) => {
    if (!id) {
      return;
    }
    topupCard.mutate(
      { ...data, card: id },
      {
        onSuccess,
        // @ts-ignore
        onError,
      },
    );
  };

  return (
    <div className="accent-box-v2 bg-menuDark mt-10">
      <Box mb="20px">
        <Alert severity="info" variant="outlined">
          Min topup cost is 10$
        </Alert>
      </Box>
      <form onSubmit={handleSubmit(onConfirm)}>
        <h5>Amount</h5>
        <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                {...field}
                type="number"
                placeholder="Minimum 1"
                error={!!errors.amount}
                sx={{
                  bgcolor: '#2B2B2C',
                }}
                inputProps={{
                  sx: {
                    bgcolor: '#2B2B2C !important',
                    borderColor: 'transparent !important',
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>USDT</Typography>
                  </InputAdornment>
                }
              />
            )}
          />

          <FormHelperText sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}>
            <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
            <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
              {currentUser?.free_balance} USDT
            </Typography>
          </FormHelperText>
        </FormControl>

        {formAmount && (
          <div className="content d-flex justify-content-between mt-20">
            <div className="title">
              <h5 className="mb-10">Details</h5>
              <Typography className="text-secondary text-small mb-4">Service fee</Typography>
              <Typography className="text-primary text-small mb-4" sx={{ mt: '10px' }}>
                Total write off
              </Typography>
            </div>
            <div className="box-price mt-32">
              <Typography className="text-small mb-4 text-white" sx={{ textAlign: 'right' }}>
                {CARD_API_FEE * 100} %
              </Typography>
              <Typography
                className="text-small mb-4 text-white"
                sx={{ fontWeight: 700, mt: '10px', textAlign: 'right' }}
              >
                {Number(formAmount) + Number(formAmount * CARD_API_FEE)} USDT
              </Typography>
            </div>
          </div>
        )}

        <div className="mt-10">
          <ConfirmDialog
            title="Confirmation"
            content="The card will be topuped, please make sure the data entered is correct"
            onConfirm={handleSubmit(onConfirm)}
          >
            <LoadingButton loading={topupCard.isLoading} className="tf-btn lg primary">
              Topup
            </LoadingButton>
          </ConfirmDialog>
        </div>
      </form>
    </div>
  );
};
