import { SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';

export default function useAlert() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = (snackbarId: SnackbarKey) => (
    <button type="button" className="tf-btn sm" style={{ background: 'transparent' }} onClick={() => { closeSnackbar(snackbarId); }}>
      Close
    </button>
  );

  const alert = (text: string, variant: 'success' | 'error') => enqueueSnackbar(text, { variant, action });

  return { alert };
}
