/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable new-cap */
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import theme from 'theme';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'static/fonts/fonts.css';
import 'static/fonts/font-icons.css';
import 'static/bootstrap.min.css';
import 'index.css';
import './lib/i18n';

localStorage.removeItem('access_token');

if (process.env.REACT_APP_ENV === 'production') {
  try {
    Sentry.init({
      dsn: 'https://f1e0dfb7e0cc4cce9cb913c7d0a0a246@o430710.ingest.us.sentry.io/5379723',
      // @ts-ignore
      integrations: [new Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  } catch (e) {
    console.error(e);
  }
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <App />
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
