// import { useCallback } from 'react';
import { useMutation } from 'react-query';

// import { ApiTokenEntity } from 'model/apiToken';
import { ApiTokenRefreshRequest, ApiTokenRequest } from 'api/apiToken/types';
import { ClientApiTokenApi } from 'api/apiToken';
import { ApiTokenEntity } from 'model/apiToken';
import { noop } from 'lodash';

export const useApiTokenMutation = (onError = noop) => {
  // const queryClient = useQueryClient();

  // const onSuccess = useCallback(
  //   (form: ApiTokenEntity) => {
  //     queryClient.setQueryData<ApiTokenEntity>(key || '', (data) => ({
  //       ...data,
  //       ...form,
  //     }));
  //   },
  //   [queryClient, key],
  // );

  const createOrUpdateToken = useMutation(
    (data: ApiTokenRequest) => {
      const api = new ClientApiTokenApi();
      return api.createOrUpdateToken(data);
    },
    {
      onSuccess: (form: ApiTokenEntity) => {
        if (form?.access_token) {
          localStorage.setItem('access_token', form.access_token);
        }

        // if (form?.refresh_token?.refresh_token) {
        //   localStorage.setItem('refresh_token', form.refresh_token?.refresh_token);
        // }
      },
      onError,
    },
  );

  const createOrUpdateWebhook = useMutation(
    (data: ApiTokenRefreshRequest) => {
      const api = new ClientApiTokenApi();
      return api.refreshToken(data);
    },
    // { onSuccess },
  );

  return {
    createOrUpdateToken,
    createOrUpdateWebhook,
  };
};
