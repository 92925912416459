import { Card, CardContent, Typography, CardActions, IconButton } from '@mui/material';
import React, { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

type SelectedCardProps = {
  isActive: boolean;
  title: string;
  text: string;
  onClick: () => void;
  sx?: Record<string, string>;
};

export const SelectedCard: FC<SelectedCardProps> = ({
  isActive,
  title,
  text,
  onClick,
  sx = {},
}) => (
  <Card
    variant="outlined"
    sx={{
      bgcolor: 'transparent',
      borderColor: isActive ? '#25c866' : '#7e8088',
      position: 'relative',
      ...sx,
    }}
    onClick={onClick}
  >
    <CardContent>
      <Typography
        variant="h6"
        component="div"
        sx={{ color: isActive ? '#fff' : 'inherit', fontSize: '1rem' }}
      >
        {title}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
        {text}
      </Typography>
    </CardContent>
    <CardActions sx={{ position: 'absolute', top: '0', right: '0', p: 0 }}>
      <IconButton size="small">
        {isActive ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <RadioButtonUncheckedIcon sx={{ color: '#7e8088' }} />
        )}
      </IconButton>
    </CardActions>
  </Card>
);
