import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useUserCardsQuery } from 'queries/cards';
import { CardPreview } from 'components/Cards/CardPreview';

export const CardsList = () => {
  const { data: cards } = useUserCardsQuery();

  return (
    <>
      {(cards?.length ?? 0) > 0 && (
        <Grid container spacing={2}>
          {cards?.map((card) => (
            <Grid item xs={12} key={card.id}>
              {card.status?.toLowerCase() !== 'creating' && (
                <Link to={`/cards/${card?.id}`}>
                  <CardPreview card={card} />
                </Link>
              )}
              {card.status?.toLowerCase() === 'creating' && <CardPreview card={card} />}
            </Grid>
          ))}
        </Grid>
      )}
      {!cards?.length && <Typography>No cards issued yet</Typography>}
    </>
  );
};
