import React, { useState } from 'react';
import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { SellForm } from 'components/P2P/Express/SellForm';
import { BuyForm } from 'components/P2P/Express/BuyForm';
import { Alert, Box } from '@mui/material';

const TABS = [
  {
    key: 0,
    title: 'Buy',
  },
  {
    key: 1,
    title: 'Sell',
  },
];

export const Express = () => {
  const [searchParams] = useSearchParams();
  const hasMethodUrl = !!searchParams?.get('method');
  const [activeTab, setActiveTab] = useState(searchParams?.get('method') === 'sell' ? 1 : 0);

  const handleChangeTabs = (newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className="pt-70 pb-16">
      <div className="bg-menuDark tf-container">
        <div className="tf-tab pt-12 mt-4">
          {!hasMethodUrl && (
            <div className="tab-slide">
              <ul className="nav nav-tabs wallet-tabs" role="tablist">
                <li className="item-slide-effect" />
                {TABS.map((tab) => (
                  <li
                    key={tab.key}
                    className={cn({
                      'nav-item': true,
                      active: activeTab === tab.key,
                    })}
                    role="presentation"
                    onClick={() => handleChangeTabs(tab.key)}
                  >
                    <button
                      type="button"
                      className={cn({
                        'nav-link': true,
                        active: activeTab === tab.key,
                      })}
                      data-bs-toggle="tab"
                      data-bs-target={`#${tab.title}`}
                    >
                      {tab.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {hasMethodUrl && (
            <Box>
              <Alert severity="info" variant="outlined">
                Some info text placeholder
              </Alert>
            </Box>
          )}
          <div className="tab-content pt-16 pb-16">
            <div
              className={`tab-pane fade ${activeTab === 0 ? 'active show' : ''}`}
              id="history"
              role="tabpanel"
            >
              <BuyForm />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 1 ? 'active show' : ''}`}
              id="market"
              role="tabpanel"
            >
              <SellForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
