import { Query, useQuery } from 'react-query';

export type QueryProps<T> = {
  key: string
  enabled: boolean
  retry?: number
  retryDelay?: number
  method: () => Promise<T>
  onSuccess?: (courses: T) => void
  onError?: (err: unknown) => void
  refetchInterval?: number | false | ((data: T | undefined, query: Query<T, unknown, T, string>) => number | false) | undefined;
};

export function useQueryBuilder<T>({
  key, enabled, retry = 1, method, onSuccess, onError, refetchInterval, retryDelay,
}: QueryProps<T>) {
  const props = useQuery(key, async () => method(), {
    refetchOnWindowFocus: false,
    retryDelay: retryDelay ?? 3000,
    retry,
    enabled,
    onSuccess,
    onError,
    refetchInterval,
  });

  return props;
}
