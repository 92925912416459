import { Request } from 'lib/request';
import { AxiosInstance } from 'axios';
import { ApiTokenRefreshRequest, ApiTokenRequest, ApiTokenResponse } from 'api/apiToken/types';

export class ClientApiTokenApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = new Request().apiClient;
  }

  async createOrUpdateToken(token: ApiTokenRequest): Promise<ApiTokenResponse> {
    const response = await this.client.post('/token/', token);
    return response.data?.data;
  }

  async refreshToken(token: ApiTokenRefreshRequest): Promise<ApiTokenResponse> {
    const response = await this.client.post('/token/refresh/', token);
    return response.data;
  }
}
