import { Splash } from 'components/Splash';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';

function App() {
  const { isLoading } = useAuth();

  return isLoading ? <Splash /> : <RouterProvider router={router} />;
}

export default App;
