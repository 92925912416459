import React, { useState } from 'react';
import { ListCard } from 'components/Send/ListCard';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { Box, Button, Grid, SwipeableDrawer, Typography } from '@mui/material';
import { ManagePaymentMethods } from 'components/P2P/ManagePaymentMethods';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useP2POffersUserListQuery } from 'queries/p2p';

const TABS = [
  {
    key: 0,
    title: 'Buy',
  },
  {
    key: 1,
    title: 'Sale',
  },
];

export const Ads = () => {
  const { data: offers } = useP2POffersUserListQuery();
  const [activeTab, setActiveTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleChangeTabs = (newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className="pt-70 pb-16">
      <div className="tf-container">
        <Box className="mt-20">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                className="accent-box-v2 item-check-style3 bg-menuDark"
                onClick={toggleDrawer(true)}
              >
                <ListCard title="Payment settings" description="Manage your payment methods" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="bg-menuDark tf-container mt-16">
        <div className="tf-tab pt-12 mt-4">
          <h4 className="mb-10">Your Ads</h4>
          <Box mt="10px" mb="10px" width="max-content">
            <Link to="/p2p/ads/create">
              <Button size="small" startIcon={<AddCircleOutlineIcon />}>
                Create ad
              </Button>
            </Link>
          </Box>
          <div className="tab-slide">
            <ul className="nav nav-tabs wallet-tabs" role="tablist">
              <li className="item-slide-effect" />
              {TABS.map((tab) => (
                <li
                  key={tab.key}
                  className={cn({
                    'nav-item': true,
                    active: activeTab === tab.key,
                  })}
                  role="presentation"
                  onClick={() => handleChangeTabs(tab.key)}
                >
                  <button
                    type="button"
                    className={cn({
                      'nav-link': true,
                      active: activeTab === tab.key,
                    })}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.title}`}
                  >
                    {tab.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="tab-content pt-16 pb-16">
            <div
              className={`tab-pane fade ${activeTab === 0 ? 'active show' : ''}`}
              id="purchases"
              role="tabpanel"
            >
              {offers && offers?.filter((offer) => offer.type === 'buy')?.length !== 0 && (
                <ul>
                  {offers
                    ?.filter((offer) => offer.type === 'buy')
                    ?.map((offer, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li
                        key={offer.id}
                        className={idx !== 0 ? 'mt-8' : ''}
                        style={{ opacity: offer.is_active ? '1' : '0.7' }}
                      >
                        <Link
                          to={`/p2p/ads/edit/${offer.id}`}
                          className="coin-item style-1 gap-12 bg-surface"
                        >
                          <div className="content">
                            <div className="title">
                              <Typography
                                className="text-large"
                                sx={{ color: '#fff', fontWeight: 500 }}
                              >
                                {offer.price} {offer.currency_pair?.quote_currency} per 1{' '}
                                {offer.currency_pair?.base_currency}
                              </Typography>
                              <span className="text-secondary">
                                {DateTime.fromISO(offer.created_at).toLocaleString(
                                  DateTime.DATETIME_MED,
                                )}
                              </span>
                            </div>
                            <div className="box-price">
                              <Typography className="text-small mb-4 text-white">
                                {offer.down_limit ?? 0} - {offer.top_limit ?? 0}{' '}
                                {offer.currency_pair?.quote_currency}
                              </Typography>
                              <p className="text-secondary text-right">Limits</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
              {offers && offers?.filter((offer) => offer.type === 'buy')?.length === 0 && (
                <Typography>No ads yet</Typography>
              )}
            </div>
            <div
              className={`tab-pane fade ${activeTab === 1 ? 'active show' : ''}`}
              id="sale"
              role="tabpanel"
            >
              {offers && offers?.filter((offer) => offer.type === 'sell')?.length !== 0 && (
                <ul>
                  {offers
                    ?.filter((offer) => offer.type === 'sell')
                    ?.map((offer, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li
                        key={offer.id}
                        className={idx !== 0 ? 'mt-8' : ''}
                        style={{ opacity: offer.is_active ? '1' : '0.7' }}
                      >
                        <Link
                          to={`/p2p/ads/edit/${offer.id}`}
                          className="coin-item style-1 gap-12 bg-surface"
                        >
                          <div className="content">
                            <div className="title">
                              <Typography
                                className="text-large"
                                sx={{ color: '#fff', fontWeight: 500 }}
                              >
                                {offer.price} {offer.currency_pair?.quote_currency} per 1{' '}
                                {offer.currency_pair?.base_currency}
                              </Typography>
                              <span className="text-secondary">
                                {DateTime.fromISO(offer.created_at).toLocaleString(
                                  DateTime.DATETIME_MED,
                                )}
                              </span>
                            </div>
                            <div className="box-price">
                              <Typography className="text-small mb-4 text-white">
                                {offer.down_limit ?? 0} - {offer.top_limit ?? 0}{' '}
                                {offer.currency_pair?.quote_currency}
                              </Typography>
                              <p className="text-secondary text-right">Limits</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
              {offers && offers?.filter((offer) => offer.type === 'sell')?.length === 0 && (
                <Typography>No ads yet</Typography>
              )}
            </div>
          </div>
        </div>
      </div>

      <SwipeableDrawer
        anchor="right"
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="modal mRight">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
                <span
                  className="left"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  onClick={toggleDrawer(false)}
                >
                  <i className="icon-left-btn" />
                </span>
                <h3>Manage payment methods</h3>
              </div>
              <div className="overflow-auto pt-70 pb-16">
                <div className="tf-container">
                  <ManagePaymentMethods
                    onSubmitSuccess={() => {
                      setDrawerOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};
