/* eslint-disable import/no-extraneous-dependencies */
import React, { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import WebApp from '@twa-dev/sdk';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
  MenuItem,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm, Controller } from 'react-hook-form';
import { BalanceWithdrawRequest } from 'api/balance/types';
import { useUsersQuery } from 'queries/users';
import { useBalanceMutation } from 'queries/balance';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import useAlert from 'hooks/useAlert';

const NETWORKS = [
  {
    id: 'TRC20',
    name: 'TRC-20',
  },
  {
    id: 'BEP',
    name: 'BEP-20',
  },
  {
    id: 'BTC',
    name: 'BTC',
  },
];

export const Crypto: FC = () => {
  const navigate = useNavigate();
  const { alert } = useAlert();
  const { data } = useUsersQuery();
  const currentUser = data;
  const schema = yup
    .object({
      amount: yup
        .number()
        .required()
        .min(1)
        .max(currentUser?.free_balance ?? +Infinity),
      external_address: yup.string().required(),
    })
    .required();
  const { withdraw } = useBalanceMutation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      external_address: '',
      amount: 0,
      network: 'TRC20',
      currency: 'USDT',
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    navigate('/success', {
      state: {
        message:
          'Withdraw operation completed successfully. You can check transaction in home page',
      },
    });
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = (data: BalanceWithdrawRequest) => {
    withdraw.mutate(data, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  const onQrButtonClick = () => {
    try {
      WebApp.showScanQrPopup({}, (text: string) => {
        WebApp.closeScanQrPopup();
        setValue('external_address', text);
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="pt-90 pb-90">
      <form onSubmit={handleSubmit(onConfirm)}>
        <div className="tf-container">
          <div className="mt-4 accent-box-v2 bg-menuDark">
            <div>
              <h5>Address</h5>

              <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                <Controller
                  name="external_address"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      error={!!errors.external_address}
                      placeholder="Long press to paste"
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <QrCodeScannerIcon color="secondary" onClick={onQrButtonClick} />
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </FormControl>

              <h5 className="mt-20">Network</h5>

              <FormControl sx={{ mt: '10px', width: '100%' }}>
                <Controller
                  name="network"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="disabled"
                      placeholder="Select network"
                      error={!!errors.network}
                      displayEmpty
                      input={
                        <OutlinedInput
                          placeholder="Select network"
                          sx={{
                            bgcolor: '#2B2B2C',
                          }}
                          inputProps={{
                            sx: {
                              bgcolor: '#2B2B2C !important',
                              borderColor: 'transparent !important',
                              color: '#fff',
                            },
                          }}
                        />
                      }
                    >
                      {NETWORKS.map((network) => (
                        <MenuItem key={network.id} value={network.id}>
                          {network.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>

              <h5 className="mt-20">Total amount</h5>

              <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      type="number"
                      error={!!errors.amount}
                      placeholder="Minimum 1"
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>USDT</Typography>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <FormHelperText
                  sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}
                >
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
                    {currentUser?.free_balance} USDT
                  </Typography>
                </FormHelperText>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="tf-container mt-10">
          <ConfirmDialog
            title="Confirmation"
            content="The withdraw cannot be returned, please make sure the data entered is correct"
            onConfirm={handleSubmit(onConfirm)}
          >
            <LoadingButton loading={withdraw.isLoading} className="tf-btn lg primary">
              Confirm
            </LoadingButton>
          </ConfirmDialog>
        </div>
      </form>
    </div>
  );
};
