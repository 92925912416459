import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { LocalCard } from 'model/cards';
import { chunk } from 'lodash';

type CardPreviewProps = {
  card: LocalCard;
};

export const CardPreview: FC<CardPreviewProps> = ({ card }) => (
  <Box
    borderRadius="10px"
    padding="20px"
    bgcolor="#393737"
    style={{
      opacity: card?.status?.toUpperCase() !== 'ACTIVE' ? '0.5' : '1',
    }}
  >
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography className="text-white" sx={{ fontSize: '14px', fontWeight: 600 }}>
          Card number
        </Typography>
        <Typography className="text-secondary2">
          {chunk(card?.mask_number ?? '', 4)
            .join(' ')
            .replaceAll(',', '')}
        </Typography>
      </Box>
      <Box>
        <Typography className="text-white" sx={{ fontSize: '14px', fontWeight: 600 }}>
          **/**
        </Typography>
        <Typography className="text-white" sx={{ fontSize: '12px' }}>
          {card.status}
        </Typography>
      </Box>
    </Box>
    {/* <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography className="text-white" sx={{ fontSize: '24px', fontWeight: 600 }}>
          $ 88.200,00
        </Typography>
      </Box>
      <Box>
        <Typography className="text-white" sx={{ fontSize: '14px', fontWeight: 600 }}>
          03/25
        </Typography>
        <Typography className="text-white" sx={{ fontSize: '12px' }}>
          Valid
        </Typography>
      </Box>
    </Box> */}
  </Box>
);
