import React, { FC } from 'react';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import cn from 'classnames';
import { mapStatus, OfficeHistory } from 'model/office';
import { DateTime } from 'luxon';
import useAlert from 'hooks/useAlert';
import { useOfficeMutation } from 'queries/office/mutation';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { Box, Typography } from '@mui/material';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { LoadingButton } from '@mui/lab';
import { useOperationOfficeOffersQuery } from 'queries/office';
import { SelectedCard } from 'components/ui/SelectedCard';
import { noop } from 'lodash';

type OfficeOrderViewProps = {
  transaction?: Partial<OfficeHistory>;
  refetch: () => void;
};

export const OfficeOrderView: FC<OfficeOrderViewProps> = ({ transaction, refetch }) => {
  const { alert } = useAlert();
  const { statusOrder } = useOfficeMutation();
  const { data: offers } = useOperationOfficeOffersQuery(
    transaction?.id ?? 0,
    !!transaction?.id,
    noop,
    10000,
  );

  const onCancelStatus = () => {
    if (!transaction) {
      return;
    }
    const requestData: { status: OfficeHistory['status'] } & { operation: number } = {
      status: 'CANCELLED_CLIENT',
      operation: transaction?.child_operation ?? (transaction.id as number),
    };

    const onSuccess = () => {
      alert(`Order cancelled by client`, 'success');
      refetch();
    };

    const onError = (error: AxiosError<DefaultError>) => {
      const errorMessage = error.response?.data?.detail;
      alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
    };

    statusOrder.mutate(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  const onSelectOfferStatus = (offerId: number) => {
    if (!offerId) {
      return;
    }
    const requestData: { status: OfficeHistory['status'] } & { operation: number } = {
      status: 'CONFIRMED_BY_CLIENT',
      operation: offerId,
    };

    const onSuccess = () => {
      alert(`Offer has been selected by client`, 'success');
      refetch();
    };

    const onError = (error: AxiosError<DefaultError>) => {
      const errorMessage = error.response?.data?.detail;
      alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
    };

    statusOrder.mutate(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  const onConfirmRecievingStatus = () => {
    if (!transaction?.child_operation) {
      return;
    }
    const requestData: { status: OfficeHistory['status'] } & { operation: number } = {
      status: 'COMPLETION_CLIENT',
      operation: transaction.child_operation as number,
    };

    const onSuccess = () => {
      alert(`Recieving has been confirmed by client`, 'success');
      refetch();
    };

    const onError = (error: AxiosError<DefaultError>) => {
      const errorMessage = error.response?.data?.detail;
      alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
    };

    statusOrder.mutate(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  return (
    <div className="pt-70 pb-16">
      <div className="tf-container">
        <div className="mt-4 text-center">
          <h3 className="mt-40">Order info</h3>
          <div className="mb-32">
            {transaction?.sell_amount && (
              <h1 className="d-inline-block text-primary">
                {Number(transaction?.sell_amount).toFixed(2)}
              </h1>
            )}
            <h4 className="d-inline-block text-primary">
              {' '}
              {transaction?.sell_currency ?? 'USDT'} to {transaction?.buy_currency}
            </h4>
          </div>
          <ul className="mt-12 accent-box-v4 bg-menuDark">
            <li className="d-flex item-check-style3">
              <div className="content d-flex justify-content-between text-white">
                {transaction?.status && transaction?.status !== 'COMPLETION_CLIENT' && (
                  <AccessTimeFilledOutlinedIcon fontSize="large" />
                )}
                {transaction?.status && transaction?.status === 'COMPLETION_CLIENT' && (
                  <CheckCircleIcon fontSize="large" color="success" />
                )}
                <div className="content">
                  <div className="title d-flex align-items-start flex-column">
                    <span className="mt-4 text-secondary">Status</span>
                    {transaction?.status && (
                      <p className="text-extra-large">{mapStatus[transaction.status]}</p>
                    )}
                  </div>
                </div>
              </div>
            </li>
            {/* <li className="d-flex pt-8 item-check-style3">
            <div className="content d-flex justify-content-between text-white">
              <WarningOutlinedIcon fontSize="large" />
              <div className="content">
                <div className="title d-flex align-items-start flex-column">
                  <span className="mt-4 text-secondary">Important</span>
                  <p className="text-extra-large text-start">Buyer must confirm the order in 40 minutes after creating</p>
                </div>
              </div>
            </div>
          </li> */}
          </ul>

          <ul className="mt-12 accent-box-v4 bg-menuDark">
            <li className="d-flex align-items-center justify-content-between pb-8 list-view-custom">
              <span className="text-small">Service fee</span>
              <span className="text-large text-white">0 USDT</span>
            </li>

            {transaction?.buy_amount && (
              <li className="d-flex align-items-center justify-content-between pt-8 line-t list-view-custom">
                <span className="text-small">Recieving amount</span>
                <span className="text-large text-white">
                  {Number(transaction?.buy_amount).toFixed(2)} {transaction?.buy_currency}
                </span>
              </li>
            )}
          </ul>

          <ul className="mt-12 accent-box-v4 bg-menuDark">
            {transaction?.price && (
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt list-view-custom">
                <span className="text-small">Price per 1 USDT</span>
                <span className="text-large text-white">
                  {Number(transaction?.price).toFixed(2)} {transaction?.buy_currency}
                </span>
              </li>
            )}
            <li className="d-flex align-items-center justify-content-between pb-8 pt-8 line-bt list-view-custom">
              <span className="text-small">Payment method</span>
              <span className="text-large text-white">{transaction?.payment}</span>
            </li>
            {transaction?.receipt && (
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt list-view-custom">
                <span className="text-small">Recieving method</span>
                <span className="text-large text-white">
                  {transaction?.receipt?.receipt_method}
                </span>
              </li>
            )}
            {transaction?.receipt?.details && (
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt list-view-custom">
                <span className="text-small">Recieving details</span>
                <span className="text-large text-white">{transaction?.receipt?.details}</span>
              </li>
            )}

            {transaction?.created_at && (
              <li className="d-flex align-items-center justify-content-between pt-8 list-view-custom">
                <span className="text-small">Creation date</span>
                <span className="text-large text-white">
                  {DateTime.fromISO(transaction?.created_at).toLocaleString(DateTime.DATETIME_MED)}
                </span>
              </li>
            )}
          </ul>
        </div>

        <h4 className="mb-10 mt-20">
          {!transaction?.child_operation ? 'Select suitable offer' : 'Offer history'}
        </h4>

        {offers?.map((offer) => (
          <Box
            key={`${offer.id}`}
            mb="10px"
            className={cn({
              disabled:
                !!transaction?.child_operation && offer.parent_operation !== transaction?.id,
            })}
          >
            {!transaction?.child_operation && (
              <ConfirmDialog
                title="Confirmation"
                content="The offer will be selected"
                onConfirm={() => {
                  onSelectOfferStatus(offer.id);
                }}
              >
                <SelectedCard
                  sx={{ height: '100%' }}
                  title={`Total: ${offer.buy_amount} ${offer.buy_currency}`}
                  text={`Details: ${offer.receipt?.receipt_method ?? '-'}, ${
                    offer.receipt?.details ?? '-'
                  }, ${offer.price} ${offer.buy_currency} per 1 ${offer.sell_currency}`}
                  isActive={
                    !!transaction?.child_operation && offer.parent_operation === transaction?.id
                  }
                  onClick={() => {}}
                />
              </ConfirmDialog>
            )}
            {!!transaction?.child_operation && (
              <SelectedCard
                sx={{ height: '100%' }}
                title={`Total: ${offer.buy_amount} ${offer.buy_currency}`}
                text={`Details: ${offer.receipt?.receipt_method ?? '-'}, ${
                  offer.receipt?.details ?? '-'
                }, ${offer.price} ${offer.buy_currency} per 1 ${offer.sell_currency}`}
                isActive={offer.parent_operation === transaction?.id}
                onClick={() => {}}
              />
            )}
          </Box>
        ))}

        {offers && offers.length === 0 && (
          <Typography>No suitable offers yet. We are selecting office offers for you</Typography>
        )}

        {transaction?.status !== 'CANCELLED_CLIENT' &&
          transaction?.status !== 'CANCELLED' &&
          transaction?.status !== 'COMPLETION_CLIENT' &&
          transaction?.status !== 'COMPLETION' &&
          !!transaction?.child_operation && (
            <Box className="mt-20">
              <ConfirmDialog
                title="Confirmation"
                content="Confirm your cash recieving. The order will be completed"
                onConfirm={onConfirmRecievingStatus}
              >
                <LoadingButton loading={statusOrder.isLoading} className="tf-btn lg primary">
                  Confirm recieving
                </LoadingButton>
              </ConfirmDialog>
            </Box>
          )}

        {transaction?.status !== 'CANCELLED_CLIENT' &&
          transaction?.status !== 'CANCELLED' &&
          transaction?.status !== 'COMPLETION_CLIENT' &&
          transaction?.status !== 'COMPLETION' && (
            <Box className="mt-20">
              <ConfirmDialog
                title="Confirmation"
                content="The order will be cancelled"
                onConfirm={onCancelStatus}
              >
                <LoadingButton
                  loading={statusOrder.isLoading}
                  variant="outlined"
                  className="tf-btn text-white lg text-xl"
                >
                  Cancel order
                </LoadingButton>
              </ConfirmDialog>
            </Box>
          )}
      </div>
    </div>
  );
};
