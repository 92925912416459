import React from 'react';
import { Link } from 'react-router-dom';
import { ListCard } from 'components/Send/ListCard';

export const Send = () => (
  <div className="pt-70 pb-16">
    <div className="tf-container">
      <div className="mt-4 text-center">
        <h5 className="mt-40 mb-40">Chose payment method</h5>
      </div>
      <ul className="mt-20">
        <li>
          <Link to="/send/cash" className="accent-box item-check-style3 bg-menuDark">
            <ListCard title="Cash" description="Use your balance and get cash at our offices" />
          </Link>
        </li>
        <li className="mt-12">
          <Link to="/send/internal" className="accent-box item-check-style3 bg-menuDark">
            <ListCard title="Send to Matex user" description="Internal 0 fee transactions" />
          </Link>
        </li>
        <li className="mt-12">
          <Link to="/send/wallet" className="accent-box item-check-style3 bg-menuDark">
            <ListCard title="External Wallet" description="Crypto transactions" />
          </Link>
        </li>
        <li className="mt-12">
          <Link to="/p2p/express?method=sell" className="accent-box item-check-style3 bg-menuDark">
            <ListCard title="MateX SuperSend" description="Send to everywhere, or exchange" />
          </Link>
        </li>
      </ul>
    </div>
  </div>
);
