import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useP2POfferOneQuery } from 'queries/p2p';
import { Controller, useForm } from 'react-hook-form';
import { OperationCreateRequest } from 'api/p2p/types';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { useP2PMutation } from 'queries/p2p/mutation';
import { ConfirmDialog } from 'components/ConfirmDialog';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import useAlert from 'hooks/useAlert';
import { P2POperation } from 'model/p2p';
import { useUsersQuery } from 'queries/users';

export const BuyOne = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const disabledAmount = searchParams?.get('amount');
  const disabledPaymentMethod = searchParams?.get('payment_method');
  const { alert } = useAlert();
  const { order } = useParams();
  const { data } = useP2POfferOneQuery(order as unknown as number, !!order);
  const { data: currentUser } = useUsersQuery();

  const schema = yup
    .object({
      base_amount: yup
        .number()
        .required()
        .min(1)
        .max(Number(data?.total) ?? +Infinity),
    })
    .required();
  const { createOperation } = useP2PMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<any>({
    defaultValues: {
      base_amount: disabledAmount ?? '0',
      saved_payment_method: disabledPaymentMethod ? Number(disabledPaymentMethod) : 0,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const formAmount = watch('base_amount');
  const isUserOwner = data?.owner?.tg_id === currentUser?.tg_id;

  const onSuccess = (response: { data: P2POperation }) => {
    navigate(`/p2p/operation/${response?.data?.id}`, { replace: true });
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = (request: OperationCreateRequest) => {
    const requestData: OperationCreateRequest = {
      ...request,
      offer: data?.id ?? 0,
      quote_amount: (Number(data?.price) * Number(request.base_amount)).toFixed(2),
    };

    createOperation.mutate(requestData, {
      // @ts-ignore
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  useEffect(() => {
    setValue('saved_payment_method', data?.saved_payment_method?.[0]?.id ?? 0);
  }, [data]);

  return (
    <div className="pt-70 pb-16">
      <form onSubmit={handleSubmit(onConfirm)}>
        <div className="tf-container">
          <div className="mt-4 text-center">
            {!isUserOwner && (
              <>
                <h3 className="mt-40">You are buying from {data?.owner?.matextag}</h3>
                <Box className="mb-32 d-flex" alignItems="flex-end" justifyContent="center">
                  {/* <h1 className="d-inline-block text-primary">1200.0</h1> */}
                  <FormControl className="no-line" sx={{ width: '200px' }} variant="outlined">
                    {disabledAmount && (
                      <Typography
                        sx={{
                          color: '#25c866 !important',
                          fontSize: '34px !important',
                          lineHeight: '41px !important',
                          fontWeight: '600 !important',
                        }}
                      >
                        {disabledAmount} {data?.currency_pair?.base_currency}
                      </Typography>
                    )}
                    {!disabledAmount && (
                      <Controller
                        name="base_amount"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="number"
                            error={!!errors.base_amount}
                            placeholder="Amount"
                            sx={{
                              bgcolor: 'transparent',
                            }}
                            autoFocus
                            endAdornment={
                              <InputAdornment position="end">
                                <h4 className="d-inline-block text-primary">
                                  {' '}
                                  {data?.currency_pair?.base_currency}
                                </h4>
                              </InputAdornment>
                            }
                            inputProps={{
                              sx: {
                                bgcolor: 'transparent !important',
                                borderColor: 'transparent !important',
                                color: '#25c866 !important',
                                fontSize: '34px !important',
                                lineHeight: '41px !important',
                                fontWeight: '600 !important',
                                padding: '0px !important',
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  </FormControl>
                </Box>
              </>
            )}
            <ul className="mt-12 accent-box-v4 bg-menuDark">
              <li className="d-flex pb-8 line-bt item-check-style3">
                <div className="content d-flex justify-content-between text-white">
                  <AccessTimeFilledOutlinedIcon fontSize="large" />
                  <div className="content">
                    <div className="title d-flex align-items-start flex-column">
                      <span className="mt-4 text-secondary">Status</span>
                      <p className="text-extra-large">
                        {!isUserOwner ? 'Creating order' : 'Active'}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="d-flex pt-8 item-check-style3">
                <div className="content d-flex justify-content-between text-white">
                  <WarningOutlinedIcon fontSize="large" color="warning" />
                  <div className="content">
                    <div className="title d-flex align-items-start flex-column">
                      <span className="mt-4 text-secondary">Important</span>
                      <p className="text-extra-large text-start">
                        Buyer must confirm the order in 40 minutes after creating
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="mt-12 accent-box-v4 bg-menuDark">
              <li className="d-flex align-items-center justify-content-between pb-8 line-bt list-view-custom">
                <span className="text-small">Service fee</span>
                <span className="text-large text-white">0 USDT</span>
              </li>
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 list-view-custom">
                <span className="text-small">Total for sending</span>
                <span className="text-large text-white">
                  {(Number(formAmount) * Number(data?.price)).toFixed(2)}{' '}
                  {data?.currency_pair?.quote_currency}
                </span>
              </li>
              <li className="d-flex align-items-center justify-content-between pt-8 line-t list-view-custom">
                <span className="text-small">Available</span>
                <span className="text-large text-white">
                  {data?.total} {data?.currency_pair?.base_currency ?? 'USDT'}
                </span>
              </li>
            </ul>

            <ul className="mt-12 accent-box-v4 bg-menuDark">
              <li className="d-flex align-items-center justify-content-between pb-8 line-bt list-view-custom">
                <span className="text-small">Limits</span>
                <span className="text-large text-white">
                  {data?.down_limit ?? 0} - {data?.top_limit ?? 0}{' '}
                  {data?.currency_pair?.quote_currency}
                </span>
              </li>
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt list-view-custom">
                <span className="text-small">Price per 1 {data?.currency_pair?.base_currency}</span>
                <span className="text-large text-white">
                  {data?.price} {data?.currency_pair?.quote_currency}
                </span>
              </li>
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt list-view-custom">
                <span className="text-small">Payment method</span>
                {!disabledPaymentMethod && !isUserOwner && (
                  <span className="text-primary">
                    <FormControl>
                      <Controller
                        name="saved_payment_method"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="small"
                            className="text-primary text-large p-0"
                            sx={{
                              padding: '0',
                              border: 'none',
                              background: 'transparent',
                            }}
                            input={<InputBase sx={{ padding: '0 !important', border: 'none' }} />}
                            IconComponent={undefined}
                            error={!!errors.saved_payment_method}
                            displayEmpty
                          >
                            {data?.saved_payment_method?.map((p) => (
                              <MenuItem key={p.id} value={p.id}>
                                {p?.payment_method?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </span>
                )}
                {disabledPaymentMethod && !isUserOwner && (
                  <span className=" text-large text-white">
                    {
                      data?.saved_payment_method?.find(
                        (p) => p.id === Number(disabledPaymentMethod),
                      )?.name
                    }
                  </span>
                )}
                {isUserOwner && (
                  <Typography sx={{ color: '#fff' }}>
                    {data?.payment_method?.map((m) => m.name).join(', ')}
                  </Typography>
                )}
              </li>
              <li className="d-flex align-items-center justify-content-between pt-8 list-view-custom">
                <span className="text-small">Time limit</span>
                <span className="text-large text-white">40 minutes</span>
              </li>
            </ul>

            {data?.comment && (
              <ul className="mt-12 accent-box-v4 bg-menuDark">
                <li className="d-flex item-check-style3">
                  <div className="content d-flex justify-content-between text-white">
                    <ChatBubbleOutlineOutlinedIcon />
                    <div className="content">
                      <div className="title d-flex align-items-start flex-column">
                        <span className="mt-4 text-secondary">Comment</span>
                        <p className="text-large text-start">{data?.comment}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
          {!isUserOwner && (
            <Box className="mt-20">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ConfirmDialog
                    title="Confirmation"
                    content="The operation will be created, please make sure the data entered is correct"
                    onConfirm={handleSubmit(onConfirm)}
                  >
                    <LoadingButton
                      loading={createOperation.isLoading}
                      variant="contained"
                      className="tf-btn text-white lg text-xl"
                    >
                      Create order
                    </LoadingButton>
                  </ConfirmDialog>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </form>
    </div>
  );
};
