import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { Ads } from 'components/P2P/Ads';
import { useUsersQuery } from 'queries/users';

export const P2PAdsPage: FC = () => {
  const { data: user } = useUsersQuery();

  return (
    <Layout header={<ModuleHeader title="My Ads" withBack />}>
      {user?.is_verified ? <Ads /> : null}
    </Layout>
  );
};
