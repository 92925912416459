import { Request } from 'lib/request';
import { AxiosInstance } from 'axios';

import {
  BalanceInnerSendRequest, BalanceWithdrawRequest, BalanceResponse, BalanceHistory,
  BalanceHistoryOne,
} from './types';

export class ClientBalanceApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = new Request().apiClient;
  }

  async innerSend(body: BalanceInnerSendRequest): Promise<BalanceResponse> {
    const response = await this.client.post('balance/transfer/internal/', body);
    return response.data;
  }

  async withdraw(body: BalanceWithdrawRequest): Promise<BalanceResponse> {
    const response = await this.client.post('/balance/transfer/crypto/withdraw/', body);
    return response.data;
  }

  async getHistory(): Promise<BalanceHistory[]> {
    const response = await this.client.get('/balance/history/');
    return response.data;
  }

  async getHistoryOne(operationId: number, type: string): Promise<BalanceHistoryOne> {
    const response = await this.client.get(`/balance/operation/${type}/${operationId}/`);
    return response.data.data;
  }
}
