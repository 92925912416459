import React from 'react';
import { Link } from 'react-router-dom';
import { ListCard } from 'components/Send/ListCard';

export const TopUp = () => (
  <div className="pt-70 pb-16">
    <div className="tf-container">
      <div className="mt-4 text-center">
        <h5 className="mt-40 mb-40">Top-up methods</h5>
      </div>
      <ul className="mt-20">
        <li>
          <Link to="/topup/crypto" className="accent-box item-check-style3 bg-menuDark">
            <ListCard title="External Wallet" description="Receive from another wallet" />
          </Link>
        </li>
        <li className="mt-12">
          <Link to="/p2p/express?method=buy" className="accent-box item-check-style3 bg-menuDark">
            <ListCard title="P2P Market" description="Top-up USDT with P2P fiat" />
          </Link>
        </li>
        <li className="mt-12">
          <Link to="/topup/cash" className="accent-box item-check-style3 bg-menuDark disabled">
            <ListCard title="Cash" description="Buy crypto with cash at our offices" />
          </Link>
        </li>
      </ul>
    </div>
  </div>
);
