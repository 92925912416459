import { useMutation, useQueryClient } from 'react-query';
import { BalanceInnerSendRequest, BalanceWithdrawRequest } from 'api/balance/types';
import { ClientBalanceApi } from 'api/balance';

export const useBalanceMutation = () => {
  const queryClient = useQueryClient();

  const innerSend = useMutation(
    (data: BalanceInnerSendRequest) => {
      const api = new ClientBalanceApi();
      return api.innerSend(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['users-data']);
      },
    },
  );

  const withdraw = useMutation(
    (data: BalanceWithdrawRequest) => {
      const api = new ClientBalanceApi();
      return api.withdraw(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['users-data']);
      },
    },
  );

  return {
    innerSend,
    withdraw,
  };
};
