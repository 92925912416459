import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { Update } from 'components/P2P/Ads/Update';
import { useUsersQuery } from 'queries/users';

export const P2PAdsUpdatePage: FC = () => {
  const { data: user } = useUsersQuery();

  return (
    <Layout header={<ModuleHeader title="Update Ad" withBack />}>
      {user?.is_verified ? <Update /> : null}
    </Layout>
  );
};
