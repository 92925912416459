import { Request } from 'lib/request';
import { AxiosInstance } from 'axios';
import {
  OfferTypes,
  P2PAddOperationMessage,
  P2PCurrencyPair,
  P2POffer,
  P2POperation,
  P2PPUserPaymentMethod,
  P2PPaymentMethod,
  P2PUpdateOperation,
  SavedPaymentMethod,
} from 'model/p2p';
import { OfferCreateRequest, OperationCreateRequest } from './types';

export class ClientP2PApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = new Request().apiClient;
  }

  async getCurrencyPairs(): Promise<P2PCurrencyPair[]> {
    const response = await this.client.get('/p2p/currency-pairs/');
    return response.data;
  }

  async getPaymentMethods(): Promise<P2PPaymentMethod[]> {
    const response = await this.client.get('/p2p/payment-methods/');
    return response.data;
  }

  async getUserPaymentMethods(): Promise<P2PPUserPaymentMethod[]> {
    const response = await this.client.get('/p2p/saved-payment-methods/list/');
    return response.data;
  }

  async createUserPaymentMethod(body: SavedPaymentMethod): Promise<P2PPaymentMethod> {
    const response = await this.client.post('/p2p/saved-payment-methods/create/', body);
    return response.data;
  }

  async createOffer(body: OfferCreateRequest): Promise<P2POffer> {
    const response = await this.client.post('/p2p/offers/create/', body);
    return response.data;
  }

  async getOfferListByTypeAndCurrency(
    type: OfferTypes,
    currencyPairId: number,
  ): Promise<P2POffer[]> {
    const response = await this.client.get(`/p2p/offers/${type}/currency/${currencyPairId}/`);
    return response.data?.data;
  }

  async getOfferUserList(): Promise<P2POffer[]> {
    const response = await this.client.get('/p2p/offers/list');
    return response.data;
  }

  async getOfferExpress(
    type: OfferTypes,
    currencyPairId: number,
    amount: string,
    paymentMethodId: number,
  ): Promise<P2POffer> {
    const response = await this.client.get(
      `/p2p/offers/express/${type}/${currencyPairId}/${amount}/${paymentMethodId}/`,
    );
    return response.data;
  }

  async getOneOffer(id: number): Promise<P2POffer> {
    const response = await this.client.get(`/p2p/offers/${id}/`);
    return response.data?.data;
  }

  async updateOffer(id: number, body: Partial<OfferCreateRequest>): Promise<P2POffer> {
    const response = await this.client.patch(`/p2p/offers/${id}/`, body);
    return response.data;
  }

  async toggleOffer(id: number): Promise<P2POffer> {
    const response = await this.client.post(`/p2p/offers/${id}/toggle/`);
    return response.data;
  }

  async createOperation(body: OperationCreateRequest): Promise<OperationCreateRequest> {
    const response = await this.client.post('/p2p/operations/create/', body);
    return response.data;
  }

  async getOneOperation(id: number): Promise<P2POperation> {
    const response = await this.client.get(`/p2p/operations/${id}/`);
    return response.data?.data;
  }

  async getOperationsList(): Promise<Array<P2POperation>> {
    const response = await this.client.get('/p2p/operations/list');
    return response.data;
  }

  async getOperationsListExpired(): Promise<Array<P2POperation>> {
    const response = await this.client.get('/p2p/operations/expired/');
    return response.data;
  }

  async updateOperation(id: number, body: P2PUpdateOperation): Promise<OperationCreateRequest> {
    const response = await this.client.put(`/p2p/operations/${id}/`, body);
    return response.data;
  }

  async addOperationMessage(
    id: number,
    body: P2PAddOperationMessage,
  ): Promise<P2PAddOperationMessage> {
    const formData = new FormData();
    formData.append('text', body.text);
    formData.append('operation', id.toString());
    if (body.img) {
      formData.append('img', body.img);
    }

    const response = await this.client.post(`/p2p/operations/${id}/messages/create/`, formData);
    return response.data;
  }
}
