import React from 'react';
import { Grid } from '@mui/material';
import { CreateOption } from 'components/Cards/Create/Option';
import { Card } from 'model/cards';

const availableCards: Array<Card['bin']> = [
  {
    scheme: 'MasterCard',
    code: '53506787',
    id: 1,
  },
  {
    scheme: 'MasterCard',
    code: '53609700',
    id: 2,
  },
  {
    scheme: 'MasterCard',
    code: '53420400',
    id: 3,
  },
  {
    scheme: 'MasterCard',
    code: '53342800',
    id: 4,
  },
  {
    scheme: 'MasterCard',
    code: '54054255',
    id: 5,
  },
  {
    scheme: 'MasterCard',
    code: '54054200',
    id: 6,
  },
];

export const OptionList = () => (
  <Grid container spacing={2}>
    {availableCards.map((card) => (
      <Grid item xs={12} key={card.id}>
        <CreateOption card={card} />
      </Grid>
    ))}
  </Grid>
);
