import useAlert from 'hooks/useAlert';
import { tgUser } from 'hooks/useAuth';
import { noop } from 'lodash';
import { useUsersQuery } from 'queries/users';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const AccountInfo: FC = () => {
  const { alert } = useAlert();
  const { data } = useUsersQuery(!!tgUser?.id, noop, 30000);

  const user = data;

  const onCopy = (tag?: string | null) => {
    if (!tag) {
      return;
    }
    navigator.clipboard.writeText(tag);
    alert('Copied!', 'success');
  };

  return (
    <>
      <div className="bg-menuDark tf-container">
        <div className="pt-12 pb-12 mt-4">
          <h4>
            <span
              className="text-primary"
              onClick={() => {
                onCopy(user?.matextag);
              }}
            >
              <span className="text-white">My MatexTag</span> {user?.matextag}
            </span>
            {/* {' '}
            -
            {' '}
            <a href="#" className="choose-account" onClick={toggleDrawer(true)}>
              <span className="dom-text">Account 1 </span>
              {' '}
&nbsp;
              <i className="icon-select-down" />
            </a>

            {' '} */}
          </h4>
          <h1 className="mt-16">
            <div>${user?.free_balance}</div>
          </h1>
          <ul className="mt-16 grid-4 m--16">
            <li>
              <Link to="/send" className="tf-list-item d-flex flex-column gap-8 align-items-center">
                <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                  <i className="icon icon-way" />
                </span>
                Send
              </Link>
            </li>
            <li>
              <Link
                to="/topup"
                className="tf-list-item d-flex flex-column gap-8 align-items-center"
              >
                <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                  <i className="icon icon-way2" />
                </span>
                Top-up
              </Link>
            </li>
            <li>
              <Link
                to="/cards"
                className="tf-list-item d-flex flex-column gap-8 align-items-center"
              >
                <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                  <i className="icon icon-credit-card" />
                </span>
                Card
              </Link>
            </li>
            <li data-bs-toggle="modal" data-bs-target="#walletHistory">
              <Link to="/p2p" className="tf-list-item d-flex flex-column gap-8 align-items-center">
                <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                  <i className="icon icon-leftRight" />
                </span>
                P2P
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span>Wallet</span>
              <a href="#" onClick={toggleDrawer(false)}>
                <span className="icon-cancel" data-bs-dismiss="modal" />
              </a>
            </div>
            <ul className="mt-20 pb-16">
              <li data-bs-dismiss="modal">
                <div className="d-flex justify-content-between align-items-center gap-8 text-large item-check active dom-value">
                  Account 1
                  {' '}
                  <i className="icon icon-check-circle" />
                  {' '}
                </div>
              </li>
              <li className="mt-4" data-bs-dismiss="modal">
                <div className="d-flex  justify-content-between gap-8 text-large item-check dom-value">
                  Account 2
                  {' '}
                  <i className="icon icon-check-circle" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </SwipeableDrawer> */}
    </>
  );
};
