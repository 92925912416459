import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ManagePaymentMethods } from 'components/P2P/ManagePaymentMethods';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useP2PCurrencyPairsQuery,
  useP2POffersExpressQuery,
  useP2PUserPaymentMethodsQuery,
} from 'queries/p2p';
import { useUsersQuery } from 'queries/users';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import useAlert from 'hooks/useAlert';
import useDebounce from 'hooks/useDebounce';

export const SellForm: FC = () => {
  const [searchParams] = useSearchParams();
  const hasMethodUrl = !!searchParams?.get('method');
  const { alert } = useAlert();
  const { data: currencyPairs } = useP2PCurrencyPairsQuery();
  const { data: userPaymentMethods } = useP2PUserPaymentMethodsQuery();
  const navigate = useNavigate();
  const { data: currentUser } = useUsersQuery();
  const schema = yup
    .object({
      amount: yup
        .number()
        .required()
        .min(1)
        .max(currentUser?.free_balance ?? +Infinity),
      currency: yup.number().required(),
      payment_method: yup.number().required(),
    })
    .required();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<any>({
    defaultValues: {
      amount: null,
      currency: null,
      payment_method: null,
      common_payment_method: null,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const formAmount = watch('amount');
  const formCurrency = watch('currency');
  const formPaymentMethod = watch('payment_method');
  const commonFormPaymentMethod = watch('common_payment_method');

  useEffect(() => {
    const paymentMethod = userPaymentMethods?.find((m) => m.id === formPaymentMethod)
      ?.payment_method?.id;
    setValue('common_payment_method', paymentMethod);
  }, [formPaymentMethod]);

  const debouncedFormAmount = useDebounce(formAmount, 600);
  const {
    data: expressOffer,
    isLoading,
    isFetched,
  } = useP2POffersExpressQuery(
    'buy',
    formCurrency,
    debouncedFormAmount,
    commonFormPaymentMethod,
    !!debouncedFormAmount && !!formCurrency && !!commonFormPaymentMethod,
  );

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const onSuccess = (data: { amount: number; payment_method: number }) => {
    navigate(
      `/p2p/sell/${expressOffer?.id}?amount=${data.amount}&saved_payment_method=${data.payment_method}`,
    );
  };

  const onError = () => {
    alert('No suitable offers', 'error');
  };

  const onConfirm = (data: { amount: number; payment_method: number }) => {
    if (expressOffer) {
      onSuccess(data);
    } else {
      onError();
    }
  };

  useEffect(() => {
    const currentPaymentMethodEntity = userPaymentMethods?.find((p) => p.id === formPaymentMethod);
    if (!currentPaymentMethodEntity) {
      return;
    }
    const currencyPair = currencyPairs?.find(
      (pair) =>
        pair.base_currency === 'USDT' &&
        pair.quote_currency === currentPaymentMethodEntity?.payment_method?.currency.name,
    );
    if (!currencyPair) {
      onError();
      return;
    }

    setValue('currency', currencyPair.id);
  }, [formPaymentMethod]);

  return (
    <>
      <div className="mt-4 accent-box-v2 bg-menuDark">
        <form onSubmit={handleSubmit(onConfirm)}>
          <h5>Total amount</h5>

          <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  className={cn({
                    // pointerNone: step !== 0,
                  })}
                  type="number"
                  placeholder="Minimum 1"
                  error={!!errors.amount}
                  sx={{
                    bgcolor: '#2B2B2C',
                  }}
                  inputProps={{
                    sx: {
                      bgcolor: '#2B2B2C !important',
                      borderColor: 'transparent !important',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>USDT</Typography>
                    </InputAdornment>
                  }
                />
              )}
            />

            <FormHelperText
              sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}
            >
              <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
              <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
                {currentUser?.free_balance} USDT
              </Typography>
            </FormHelperText>
          </FormControl>

          <h5 className="mt-20">Payment method</h5>

          <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
            <Controller
              name="payment_method"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  error={!!errors.payment_method}
                  input={
                    <OutlinedInput
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                          color: '#fff',
                        },
                      }}
                    />
                  }
                >
                  {userPaymentMethods?.map((method) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={`${method?.id}`} value={method.id}>
                      {method.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <FormHelperText sx={{ display: 'flex', justifyContent: 'flex-end', m: '8px 0 0 0' }}>
              <Typography sx={{ fontSize: '18px', color: '#25c866' }} onClick={toggleDrawer(true)}>
                Add
              </Typography>
            </FormHelperText>
          </FormControl>

          {!isLoading && isFetched && (
            <FormHelperText sx={{ m: '8px 0 0 0' }}>
              <Typography
                sx={{ fontSize: '14px' }}
                className={expressOffer ? 'text-primary' : 'text-red'}
              >
                {expressOffer
                  ? `Estimated price: ${expressOffer?.price} ${expressOffer.currency_pair?.quote_currency} per 1 ${expressOffer.currency_pair?.base_currency}`
                  : 'Suitable offer not found'}
              </Typography>
              {expressOffer && (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }} className="text-primary">
                  Total amount for recieving:{' '}
                  {(Number(debouncedFormAmount) * Number(expressOffer?.price)).toFixed(2)}{' '}
                  {expressOffer.currency_pair?.quote_currency}
                </Typography>
              )}
            </FormHelperText>
          )}

          <Box mt="20px">
            <LoadingButton
              loading={isLoading}
              className={cn(['tf-btn', 'lg', 'primary'], {
                disabled: !expressOffer,
              })}
              type="submit"
            >
              {hasMethodUrl ? 'Send' : 'Sell'}
            </LoadingButton>
          </Box>
        </form>
      </div>

      <SwipeableDrawer
        anchor="right"
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="modal mRight">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
                <span
                  className="left"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  onClick={toggleDrawer(false)}
                >
                  <i className="icon-left-btn" />
                </span>
                <h3>Manage payment methods</h3>
              </div>
              <div className="overflow-auto pt-70 pb-16">
                <div className="tf-container">
                  <ManagePaymentMethods
                    onSubmitSuccess={() => {
                      setDrawerOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};
