import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { TransactionView } from 'components/TransactionView';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useBalanceHistoryOneQuery } from 'queries/balance/query';
import { LoadingPage } from 'components/LoadingPage';

export const TransactionBalanceOnePage: FC = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useBalanceHistoryOneQuery(
    Number(id),
    searchParams?.get('type') ?? '',
    searchParams?.get('type') === 'internal' || searchParams?.get('type') === 'external',
  );

  return (
    <Layout header={<ModuleHeader title="Transaction" withBack />}>
      <LoadingPage progress={isLoading && !data}>
        <TransactionView transaction={{ ...state.entity, ...data }} />
      </LoadingPage>
    </Layout>
  );
};
