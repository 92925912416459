import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { WalletPage } from 'pages/Wallet';
import { SendCryptoPage } from 'pages/Send/Crypto';
import { P2PPage } from 'pages/P2P';
import { P2PBuyPage } from 'pages/P2P/Buy';
import { P2PBuyOnePage } from 'pages/P2P/Buy/BuyOne';
import { P2PSellPage } from 'pages/P2P/Sell';
import { P2PSellOnePage } from 'pages/P2P/Sell/SellOne';
import { P2PExpressPage } from 'pages/P2P/Express';
import { SendPage } from 'pages/Send';
import { SendInternalPage } from 'pages/Send/Internal';
import { TopUpPage } from 'pages/TopUp';
import { TopUpCryptoPage } from 'pages/TopUp/Crypto';
import { SuccessPage } from 'pages/Success';
import { SendCashPage } from 'pages/Send/Cash';
import { TransactionOfficeOnePage } from 'pages/Transaction/Office';
import { TransactionBalanceOnePage } from 'pages/Transaction/Balance';
import { CardsPage } from 'pages/Cards';
import { CardsCreatePage } from 'pages/Cards/Create';
import { CardsGetOnePage } from 'pages/Cards/GetOne';
import { P2PAdsPage } from 'pages/P2P/Ads';
import { P2PAdsCreatePage } from 'pages/P2P/Ads/Create';
import { P2POperationPage } from 'pages/P2P/Operation';
import { P2PAdsUpdatePage } from 'pages/P2P/Ads/Update';

export const router = createBrowserRouter([
  {
    path: '/',
    // loader: rootLoader,
    children: [
      { index: true, element: <WalletPage /> },
      {
        path: 'success',
        element: <SuccessPage />,
      },
      {
        path: 'send',
        children: [
          { index: true, element: <SendPage /> },
          {
            path: 'internal',
            element: <SendInternalPage />,
          },
          {
            path: 'wallet',
            element: <SendCryptoPage />,
          },
          {
            path: 'cash',
            element: <SendCashPage />,
          },
        ],
      },
      {
        path: 'topup',
        children: [
          { index: true, element: <TopUpPage /> },
          {
            path: 'crypto',
            element: <TopUpCryptoPage />,
          },
        ],
      },
      {
        path: 'p2p',
        children: [
          { index: true, element: <P2PPage /> },
          {
            path: 'buy',
            children: [
              { index: true, element: <P2PBuyPage /> },
              {
                path: ':order',
                element: <P2PBuyOnePage />,
              },
            ],
          },
          {
            path: 'sell',
            children: [
              { index: true, element: <P2PSellPage /> },
              {
                path: ':order',
                element: <P2PSellOnePage />,
              },
            ],
          },
          {
            path: 'express',
            children: [{ index: true, element: <P2PExpressPage /> }],
          },
          {
            path: 'ads',
            children: [
              { index: true, element: <P2PAdsPage /> },
              {
                path: 'create',
                element: <P2PAdsCreatePage />,
              },
              {
                path: 'edit/:id',
                element: <P2PAdsUpdatePage />,
              },
            ],
          },
          {
            path: 'operation',
            children: [
              {
                path: ':id',
                element: <P2POperationPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'transaction',
        children: [
          {
            path: 'office/:id',
            element: <TransactionOfficeOnePage />,
          },
          {
            path: 'balance/:id',
            element: <TransactionBalanceOnePage />,
          },
        ],
      },
      {
        path: 'cards',
        children: [
          { index: true, element: <CardsPage /> },
          {
            path: 'create',
            element: <CardsCreatePage />,
          },
          {
            path: ':id',
            element: <CardsGetOnePage />,
          },
        ],
      },
    ],
  },
]);
