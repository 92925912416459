import React, { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

type LoadingPageProps = {
  progress: boolean;
  children: React.ReactNode;
};

export const LoadingPage: FC<LoadingPageProps> = ({ progress, children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {progress ? (
      <Box display="flex" justifyContent="center" mt="110px">
        <CircularProgress color="primary" size={54} />
      </Box>
    ) : (
      children
    )}
  </>
);
