import React, { FC, useState } from 'react';
import {
  Box,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { QRCodeSVG } from 'qrcode.react';
import { ListCard } from 'components/P2P/ListCard';
import { TronChain } from 'components/Icons/TronChain';
import { BNBChain } from 'components/Icons/BNBChain';
import { useUsersTRC20Query } from 'queries/users';
import useAlert from 'hooks/useAlert';

export const Crypto: FC = () => {
  const { alert } = useAlert();

  const { data } = useUsersTRC20Query();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const ADDRESS = data?.wallet_adress ?? null;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event
        && event.type === 'keydown'
        && ((event as React.KeyboardEvent).key === 'Tab'
          || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const onCopy = (address?: string | null) => {
    if (!address) {
      return;
    }
    navigator.clipboard.writeText(address);
    alert('Copied!', 'success');
  };

  return (
    <div className="pt-70 pb-16">
      <div className="tf-container">
        <div className="mt-4 text-center">
          <h5 className="mt-40 mb-40"> You can recive USDT through one of this chains</h5>
        </div>

        <div className="accent-box item-check-style3 bg-menuDark text-white" onClick={toggleDrawer(true)}>
          <ListCard icon={<TronChain />} title="TRC-20" description="Receive from" />
        </div>

        <div className="accent-box mt-10 item-check-style3 bg-menuDark text-white disabled">
          <ListCard icon={<BNBChain />} title="BEP-20" description="Receive from" />
        </div>

        <SwipeableDrawer
          anchor="right"
          PaperProps={{
            sx: {
              width: '100%',
            },
          }}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <div className="modal mRight">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
                  <span className="left" data-bs-dismiss="modal" aria-hidden="true" onClick={toggleDrawer(false)}><i className="icon-left-btn" /></span>
                  <h3>Receive Crypto</h3>
                </div>
                <div className="overflow-auto pt-70 pb-16">
                  <div className="tf-container">
                    <Box className="text-center" sx={{ width: '100%', padding: '0 20px' }}>
                      <h3>
                        <span className="text-primary">Scan QR code</span>
                        {' '}
                        or type adress manualy
                      </h3>
                      <Box className="bg-menuDark" sx={{ mt: '20px', borderRadius: '16px', pb: '20px' }}>
                        <Box sx={{
                          bgcolor: '#fff', p: '30px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px',
                        }}
                        >
                          {ADDRESS && <QRCodeSVG level="L" size={258} value={ADDRESS} />}
                        </Box>
                        <Typography
                          sx={{
                            fontSize: '16px', fontWeight: 600, mt: '30px', p: '0 20px', wordBreak: 'break-all',
                          }}
                          className="text-primary"
                          onClick={() => {
                            onCopy(ADDRESS);
                          }}
                        >
                          {ADDRESS}
                        </Typography>

                        <Typography sx={{
                          fontSize: '12px', mt: '10px', p: '0 20px', wordBreak: 'break-all',
                        }}
                        >
                          Your USDT address
                        </Typography>
                      </Box>

                      <Typography sx={{
                        fontSize: '16px', fontWeight: 600, mt: '30px', p: '0 20px', wordBreak: 'break-word',
                      }}
                      >
                        <span className="text-white">Send only USDT TRC20 to this address.</span>
                        {' '}
                        Sending other assets may result in permanent loss.
                      </Typography>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwipeableDrawer>

      </div>
    </div>
  );
};
