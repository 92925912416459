/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { SwipeableDrawer } from '@mui/material';
import { useUsersQuery } from 'queries/users';
import { tgUser } from 'hooks/useAuth';

export const UserHeader = () => {
  const { data } = useUsersQuery(!!tgUser?.id);
  const user = data?.userdata;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event
        && event.type === 'keydown'
        && ((event as React.KeyboardEvent).key === 'Tab'
          || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <>
      <div className="header-style2 fixed-top bg-menuDark">
        <div className="d-flex justify-content-between align-items-center">
          <a className="box-account" href="#">
            {tgUser?.photo_url && <img src={tgUser?.photo_url} alt="img" className="avt" />}
            <div className="info">
              <p className="text-xsmall text-secondary">Welcome back!</p>
              <h5 className="mt-4">
                {user?.first_name ?? tgUser?.first_name ?? `#${user?.username}`}
                {' '}
                {user?.last_name ?? tgUser?.last_name ?? ''}
              </h5>
            </div>
          </a>
          {/* <div className="d-flex align-items-center gap-8">
            <a href="#" className="icon-noti box-noti" onClick={toggleDrawer(true)} />
          </div> */}
        </div>
      </div>

      <SwipeableDrawer
        anchor="right"
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="modal mRight">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true" onClick={toggleDrawer(false)}><i className="icon-left-btn" /></span>
                <h3>Notification</h3>
              </div>
              <div className="overflow-auto pt-60 pb-16">
                <div className="tf-container">
                  <ul className="mt-12">
                    <li>
                      <a href="#" className="noti-item bg-menuDark">
                        <div className="pb-8 line-bt d-flex">
                          <p className="text-button fw-6">Cointex to just tick size and trading amount precision of spots/margins and perpetual swaps</p>
                          <i className="dot-lg bg-primary" />
                        </div>
                        <span className="d-block mt-8">5 minutes ago</span>
                      </a>
                    </li>
                    <li className="mt-12">
                      <a href="#" className="noti-item bg-menuDark">
                        <div className="pb-8 line-bt d-flex">
                          <p className="text-button fw-6">Cointex to adjust components of several indexes</p>
                          <i className="dot-lg bg-primary" />
                        </div>
                        <span className="d-block mt-8">5 minutes ago</span>
                      </a>
                    </li>
                    <li className="mt-12">
                      <a href="#" className="noti-item bg-menuDark">
                        <div className="pb-8 line-bt d-flex">
                          <p className="text-button fw-6">Cointex to just tick size and trading amount precision of spots/margins and perpetual swaps</p>
                          <i className="dot-lg bg-primary" />
                        </div>
                        <span className="d-block mt-8">5 minutes ago</span>
                      </a>
                    </li>
                    <li className="mt-12">
                      <a href="#" className="noti-item bg-menuDark">
                        <div className="pb-8 line-bt">
                          <p className="text-button fw-6 text-secondary">Cointex to adjust components of several indexes</p>
                        </div>
                        <span className="d-block mt-8 text-secondary">1 day ago</span>
                      </a>
                    </li>
                    <li className="mt-12">
                      <a href="#" className="noti-item bg-menuDark">
                        <div className="pb-8 line-bt">
                          <p className="text-button fw-6 text-secondary">Cryptex wallet uses Achain network service</p>
                        </div>
                        <span className="d-block mt-8 text-secondary">1 day ago</span>
                      </a>
                    </li>
                    <li className="mt-12">
                      <a href="#" className="noti-item bg-menuDark">
                        <div className="pb-8 line-bt">
                          <p className="text-button fw-6 text-secondary">Cointex to adjust components of several indexes</p>
                        </div>
                        <span className="d-block mt-8 text-secondary">1 day ago</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};
