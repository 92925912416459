import { useMutation } from 'react-query';
import { OfferCreateRequest, OperationCreateRequest } from 'api/p2p/types';
import { ClientP2PApi } from 'api/p2p';
import { P2PAddOperationMessage, P2PUpdateOperation, SavedPaymentMethod } from 'model/p2p';

export const useP2PMutation = () => {
  const createOffer = useMutation((data: OfferCreateRequest) => {
    const api = new ClientP2PApi();
    return api.createOffer(data);
  });

  const updateOffer = useMutation((data: Partial<OfferCreateRequest> & { id: number }) => {
    const api = new ClientP2PApi();
    return api.updateOffer(data.id, data);
  });

  const toggleOffer = useMutation((id: number) => {
    const api = new ClientP2PApi();
    return api.toggleOffer(id);
  });

  const createOperation = useMutation((data: OperationCreateRequest) => {
    const api = new ClientP2PApi();
    return api.createOperation(data);
  });

  const updateOperation = useMutation((data: P2PUpdateOperation & { id: number }) => {
    const api = new ClientP2PApi();
    return api.updateOperation(data.id, data);
  });

  const addOperationMessage = useMutation((data: P2PAddOperationMessage) => {
    const api = new ClientP2PApi();
    return api.addOperationMessage(data.operation, data);
  });

  const createUserPaymentMethod = useMutation((data: SavedPaymentMethod) => {
    const api = new ClientP2PApi();
    return api.createUserPaymentMethod(data);
  });

  return {
    createOffer,
    updateOffer,
    createOperation,
    updateOperation,
    createUserPaymentMethod,
    toggleOffer,
    addOperationMessage,
  };
};
