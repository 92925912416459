import { ClientCardsApi } from 'api/cards';
import { useQueryBuilder } from 'hooks';
import { noop } from 'lodash';

export const useUserCardsQuery = (enabled = true, onSuccess = noop, onError = noop) => {
  const key = 'cards';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientCardsApi();
      const res = await api.getUserCards();

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useUserCardQuery = (
  cardId: string,
  enabled = true,
  onSuccess = noop,
  onError = noop,
) => {
  const key = `cards-${cardId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientCardsApi();
      const res = await api.getUserCard(cardId);

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useUserCardDetailsQuery = (
  cardId: string,
  enabled = true,
  onSuccess = noop,
  onError = noop,
) => {
  const key = `cards-details-${cardId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientCardsApi();
      const res = await api.getUserCardDetails(cardId);

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useUserCardBalanceQuery = (
  cardId: string,
  enabled = true,
  onSuccess = noop,
  onError = noop,
) => {
  const key = `cards-balance-${cardId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientCardsApi();
      const res = await api.getUserCardBalance(cardId);

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useUserCardTransactionsQuery = (
  cardId: string,
  enabled = true,
  onSuccess = noop,
  onError = noop,
) => {
  const key = `cards-transactions-${cardId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientCardsApi();
      const res = await api.getUserCardTransactions(cardId);

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};
