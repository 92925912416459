import React, { FC, useEffect, useState } from 'react';

type TimerProps = {
  timestamp: number;
};

const Timer: FC<TimerProps> = ({ timestamp }) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const difference = timestamp - currentTime;

      if (difference <= 0) {
        setTimeLeft(null);
      } else {
        setTimeLeft(difference);
      }
    };

    const intervalId = setInterval(calculateTimeLeft, 1000);

    calculateTimeLeft();

    return () => clearInterval(intervalId);
  }, [timestamp]);

  if (!timeLeft) {
    return <div>Expired</div>;
  }

  const days = Math.floor(timeLeft / 86400);
  const hours = Math.floor((timeLeft % 86400) / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor(timeLeft % 60);

  return (
    <div>
      {days > 0 && <div>{days} days</div>}
      {(days === 0 || !days) && (
        <>
          {hours > 0 && <div>{hours} hours</div>}
          {(hours === 0 || !hours) && (
            <>
              {minutes > 0 && <div>{minutes} minutes</div>}
              {(minutes === 0 || !minutes) && <div>{seconds} seconds</div>}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Timer;
