import React, { FC } from 'react';
import { FormControl, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { SavedPaymentMethod } from 'model/p2p';
import { useP2PPaymentMethodsQuery, useP2PUserPaymentMethodsQuery } from 'queries/p2p';
import { LoadingButton } from '@mui/lab';
import { useP2PMutation } from 'queries/p2p/mutation';
import useAlert from 'hooks/useAlert';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';

type ManagePaymentMethodsProps = {
  onSubmitSuccess?: () => void;
};

const schema = yup
  .object({
    payment_method: yup.number().required(),
    name: yup.string().required(),
    requisites: yup.string().required(),
  })
  .required();

export const ManagePaymentMethods: FC<ManagePaymentMethodsProps> = ({ onSubmitSuccess }) => {
  const { alert } = useAlert();
  const { data: paymentMethods } = useP2PPaymentMethodsQuery();
  const { data: userPaymentMethods, refetch } = useP2PUserPaymentMethodsQuery();
  const { createUserPaymentMethod } = useP2PMutation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      payment_method: null,
      name: '',
      payment_name: '',
      requisites: '',
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    alert('Payment method created successfully.', 'success');
    reset();
    refetch();
    onSubmitSuccess?.();
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = (data: SavedPaymentMethod) => {
    createUserPaymentMethod.mutate(data, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  return (
    <>
      <h3>Existed methods</h3>

      <ul className="mt-12">
        {userPaymentMethods?.map((method, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx} className="mb-10">
            <div className="noti-item bg-menuDark d-block">
              <div className="pb-8 line-bt">
                <p className="text-button text-white fw-6 mb-6">
                  {method.name} {method.payment_name && `(${method.payment_name})`}
                </p>
                <p className="text-button fw-6">{method.requisites}</p>
              </div>
              <span className="d-block mt-8">{method.payment_method?.name}</span>
            </div>
          </li>
        ))}
        {userPaymentMethods?.length === 0 && <Typography>No methods yet</Typography>}
      </ul>

      <h3 className="mt-30">Add new</h3>

      <div className="accent-box-v2 bg-menuDark mt-10">
        <form onSubmit={handleSubmit(onConfirm)}>
          <h5>Payment method</h5>
          <FormControl sx={{ mt: '10px', width: '100%' }}>
            <Controller
              name="payment_method"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  input={
                    <OutlinedInput
                      placeholder="Select country"
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                          color: '#fff',
                        },
                      }}
                    />
                  }
                  error={!!errors.payment_method}
                  displayEmpty
                >
                  {paymentMethods?.map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <h5 className="mt-20">Name</h5>

          <FormControl sx={{ mt: '10px', width: '100%' }}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  error={!!errors.name}
                  placeholder="Name"
                  sx={{
                    bgcolor: '#2B2B2C',
                  }}
                  inputProps={{
                    sx: {
                      bgcolor: '#2B2B2C !important',
                      borderColor: 'transparent !important',
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <h5 className="mt-20">Visible name</h5>

          <FormControl sx={{ mt: '10px', width: '100%' }}>
            <Controller
              name="payment_name"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  error={!!errors.name}
                  placeholder="Visible name"
                  sx={{
                    bgcolor: '#2B2B2C',
                  }}
                  inputProps={{
                    sx: {
                      bgcolor: '#2B2B2C !important',
                      borderColor: 'transparent !important',
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <h5 className="mt-20">Requisites</h5>

          <FormControl sx={{ mt: '10px', width: '100%', mb: '20px' }} variant="outlined">
            <Controller
              name="requisites"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  placeholder="Please write down your requisites"
                  multiline
                  error={!!errors.requisites}
                  sx={{
                    bgcolor: '#2B2B2C',
                    color: '#fff',
                  }}
                  inputProps={{
                    sx: {
                      bgcolor: '#2B2B2C !important',
                      borderColor: 'transparent !important',
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <LoadingButton
            loading={createUserPaymentMethod.isLoading}
            className="tf-btn lg primary"
            type="submit"
          >
            Add payment method
          </LoadingButton>
        </form>
      </div>
    </>
  );
};
