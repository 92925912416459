import { useState } from 'react';
import WebApp from '@twa-dev/sdk';
import { useUsersMutation, useUsersQuery } from 'queries/users';
import { AxiosError } from 'axios';
import { useApiTokenMutation } from 'queries/apiToken/mutation';
import { createAuthHMACSignatureFromObject } from 'utils';

const tgWebAppUser =
  process.env.REACT_APP_ENV === 'production'
    ? WebApp.initDataUnsafe?.user
    : ({ id: 1 } as Partial<typeof WebApp.initDataUnsafe.user>);
export const tgUser = {
  ...tgWebAppUser,
  referer: 1,
  language_code: 'en',
  utm: 'test',
};
const authDate = WebApp.initDataUnsafe?.auth_date ?? 0;
const hash = createAuthHMACSignatureFromObject(
  {
    tgId: tgUser.id ?? '',
    auth_date: authDate,
  },
  process.env.REACT_APP_BOT_API_TOKEN ?? '',
);

export const useAuth = () => {
  const [isCreating, setIsCreating] = useState(false);
  const { createOrUpdateToken } = useApiTokenMutation(async (error) => {
    const userDoesNotExist = error.response?.status === 403;
    if (userDoesNotExist) {
      setIsCreating(true);
      localStorage.removeItem('access_token');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await onUserCreate(tgUser);
      setIsCreating(false);
    }
  });
  const onUserFetchError = async (error: AxiosError) => {
    const userDoesNotExist = error.response?.status === 403;
    const unAuth = error.response?.status === 401;

    if (unAuth && !!tgUser?.id) {
      setIsCreating(true);
      localStorage.removeItem('access_token');
      await createOrUpdateToken.mutateAsync({
        tg_id: tgUser.id,
        tg_hash: hash,
        auth_date: authDate,
      });
      setIsCreating(false);
    }

    if (userDoesNotExist) {
      setIsCreating(true);
      localStorage.removeItem('access_token');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await onUserCreate(tgUser);
      setIsCreating(false);
    }
  };

  const accessToken = localStorage.getItem('access_token');
  const { key, isLoading, refetch } = useUsersQuery(!!tgUser?.id, onUserFetchError);
  const { create } = useUsersMutation(key, () => {
    refetch();
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onUserCreate = async (user: typeof tgUser) => {
    const requestData = {
      referer: user.referer,
      tg_id: user.id,
      userdata: { ...user },
    };
    await create.mutateAsync(requestData as any);
  };

  // useEffect(() => {
  //   const onCreate = async () => {
  //     if (!tgUser?.id) {
  //       return;
  //     }

  //     setIsCreating(true);
  //     localStorage.removeItem('access_token');
  //     await createOrUpdateToken.mutateAsync({
  //       tg_id: tgUser.id,
  //       tg_hash: hash,
  //       auth_date: authDate,
  //     });
  //     setIsCreating(false);
  //   };

  //   onCreate();
  // }, []);

  return {
    isLoading: isLoading || isCreating || !accessToken,
    // || !tgWebAppUser
  };
};
