/* eslint-disable import/no-extraneous-dependencies */
import React, { FC } from 'react';
import cn from 'classnames';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useBalanceMutation } from 'queries/balance';
import { useUsersCheckExistsQuery, useUsersQuery } from 'queries/users';
import { Controller, useForm } from 'react-hook-form';
import { BalanceInnerSendRequest } from 'api/balance/types';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import useDebounce from 'hooks/useDebounce';
import useAlert from 'hooks/useAlert';

export const Internal: FC = () => {
  const navigate = useNavigate();
  const { alert } = useAlert();
  const { data } = useUsersQuery();
  const currentUser = data;
  const { innerSend } = useBalanceMutation();

  const schema = yup
    .object({
      amount: yup
        .number()
        .required()
        .min(1)
        .max(currentUser?.free_balance ?? +Infinity),
      to_balance: yup.string().required(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<any>({
    defaultValues: {
      amount: 0,
      to_balance: null as unknown as string,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const userForSend = watch('to_balance');
  const debouncedForSend = useDebounce(userForSend, 600);

  const {
    data: existedUsers,
    isLoading,
    isFetched,
  } = useUsersCheckExistsQuery(debouncedForSend, !!debouncedForSend);

  // const [step, setStep] = useState(0);

  // const onNextButtonClick = () => {
  //   setStep(1);
  // };

  const onSuccess = () => {
    navigate('/success', {
      state: {
        message: 'Send operation completed successfully. You can check transaction in home page',
      },
    });
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = (data: BalanceInnerSendRequest) => {
    const requestData: BalanceInnerSendRequest = {
      ...data,
      to_balance: existedUsers?.[0]?.id as unknown as string,
    };
    innerSend.mutate(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  return (
    <div className="pt-90 pb-90">
      <form onSubmit={handleSubmit(onConfirm)}>
        <div className="tf-container">
          <div className="mt-4 accent-box-v2 bg-menuDark">
            <div>
              <h5>Amount Money</h5>

              <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      // className={cn({
                      //   pointerNone: step !== 0,
                      // })}
                      type="number"
                      error={!!errors.amount}
                      placeholder="Minimum 1"
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>USDT</Typography>
                        </InputAdornment>
                      }
                    />
                  )}
                />

                <FormHelperText
                  sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}
                >
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
                    {currentUser?.free_balance} USDT
                  </Typography>
                </FormHelperText>
              </FormControl>

              <h5 className="mt-20">MatexTag</h5>

              <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                <Controller
                  name="to_balance"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      // className={cn({
                      //   pointerNone: step !== 0,
                      // })}
                      error={!!errors.to_balance}
                      placeholder="MatexTag"
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                        },
                      }}
                      endAdornment={
                        isLoading ? (
                          <InputAdornment position="end">
                            <CircularProgress color="primary" size={24} />
                          </InputAdornment>
                        ) : null
                      }
                    />
                  )}
                />

                {!isLoading && isFetched && (
                  <FormHelperText
                    sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}
                  >
                    <Typography
                      sx={{ fontSize: '14px' }}
                      className={
                        existedUsers && existedUsers?.length > 0 ? 'text-primary' : 'text-red'
                      }
                    >
                      User:
                      {existedUsers && existedUsers?.length > 0
                        ? ` ${existedUsers?.[0]?.userdata?.first_name ?? ''} ${
                            existedUsers?.[0]?.userdata?.last_name ?? ''
                          } (${existedUsers?.[0]?.userdata?.username ?? ''})`
                        : ' not found'}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>

        <div className="tf-container mt-10">
          {/* {step === 0 && <Button className="tf-btn lg primary" onClick={onNextButtonClick}>Next</Button>} */}
          {/* {step === 1 && <Button className="tf-btn lg primary" type="submit">Confirm</Button>} */}
          {/* <LoadingButton loading={innerSend.isLoading} className="tf-btn lg primary" type="submit">Confirm</LoadingButton> */}
          <ConfirmDialog
            title="Confirmation"
            content="The transfer cannot be returned, please make sure the data entered is correct"
            onConfirm={handleSubmit(onConfirm)}
          >
            <LoadingButton
              loading={innerSend.isLoading}
              className={cn([
                'tf-btn',
                'lg',
                'primary',
                { disabled: !existedUsers || existedUsers?.length === 0 },
              ])}
            >
              Confirm
            </LoadingButton>
          </ConfirmDialog>
        </div>
      </form>
    </div>
  );
};
