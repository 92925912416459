import { ClientOfficeApi } from 'api/office';
import { useQueryBuilder } from 'hooks';
import { noop } from 'lodash';
import { OfficeHistory } from 'model/office';
import { Query } from 'react-query';

export const useCountriesQuery = (enabled = true, onError = noop) => {
  const key = 'office-countries';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getCountries();

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useCurrencyPairsQuery = (country: number, enabled = true, onError = noop) => {
  const key = `office-currency-pairs-${country}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getCurrencyPairs(country);

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useOfficeRecievingMethodsQuery = (
  countryId: number,
  currencyPairId: number,
  enabled = true,
  onError = noop,
) => {
  const key = `office-recieving-methods-${countryId}-${currencyPairId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getOfficeRecievingMethods(countryId, currencyPairId);

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useOfficeOffersQuery = (
  {
    countryId,
    currencyPairId,
    receiptMethodId,
    amount,
  }: {
    countryId: number;
    currencyPairId: number;
    receiptMethodId: number;
    amount?: string;
  },

  enabled = true,
  onError = noop,
) => {
  const key = `office-offer-${countryId}-${currencyPairId}-${receiptMethodId}-${amount}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getOfficeOffer(countryId, currencyPairId, receiptMethodId, amount);

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useOfficeDetailsMethodQuery = (
  receiptId: number,
  enabled = true,
  onSuccess = noop,
) => {
  const key = `office-details-method-${receiptId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getOfficeDetailsMethod(receiptId);

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};

export const useOfficeHistoryQuery = (enabled = true, onSuccess = noop) => {
  const key = 'office-history';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getOperationsHistory();

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};

export const useOfficeHistoryOneQuery = (
  operationId: number,
  enabled = true,
  onSuccess = noop,
  refetchInterval:
    | number
    | false
    | ((
        data: OfficeHistory | undefined,
        query: Query<OfficeHistory, unknown, OfficeHistory, string>,
      ) => number | false)
    | undefined = false,
) => {
  const key = `office-history-operation-${operationId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    refetchInterval,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getOperationsHistoryOne(operationId);

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};

export const useOperationOfficeOffersQuery = (
  operationId: number,
  enabled = true,
  onSuccess = noop,
  refetchInterval:
    | number
    | false
    | ((
        data: OfficeHistory[] | undefined,
        query: Query<OfficeHistory[], unknown, OfficeHistory[], string>,
      ) => number | false)
    | undefined = false,
) => {
  const key = `office-operation-offers-${operationId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    refetchInterval,
    method: async () => {
      const api = new ClientOfficeApi();
      const res = await api.getOperationOfficeOffers(operationId);

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};
