import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import React, {
  FC, JSXElementConstructor, ReactElement, cloneElement, useState,
} from 'react';

type ConfirmDialogProps = {
  title: string;
  content: string;
  onConfirm: any;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
};

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title, onConfirm, content, children,
}) => {
  const [open, setOpen] = useState(false);

  const clonedChildren = cloneElement(
    children,
    { onClick: () => setOpen(true) },
  );

  return (
    <>
      {clonedChildren}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
        PaperProps={{
          sx: {
            backgroundColor: '#222223',
            maxWidth: '350px',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent sx={{ fontSize: '14px' }}>{content}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            color="secondary"
            className="tf-btn lg"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="tf-btn lg primary"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
