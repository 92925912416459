import { ClientBalanceApi } from 'api/balance';
import { useQueryBuilder } from 'hooks';
import { noop } from 'lodash';

export const useBalanceHistoryQuery = (enabled = true, onError = noop) => {
  const key = 'balance-history';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientBalanceApi();
      const res = await api.getHistory();

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useBalanceHistoryOneQuery = (operationId: number, type: string, enabled = true, onSuccess = noop) => {
  const key = `balance-history-operation-${operationId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientBalanceApi();
      const res = await api.getHistoryOne(operationId, type);

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};
