import { ClientEnvApi } from 'api/env';
import { useQueryBuilder } from 'hooks';
import { noop } from 'lodash';

export const useEnvQuery = (enabled = true, onError = noop) => {
  const key = 'env';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientEnvApi();
      const res = await api.getEnv();

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};
