import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { TopUp } from 'components/TopUp';

export const TopUpPage: FC = () => (
  <Layout header={<ModuleHeader title="Top-up" withBack />}>
    <TopUp />
  </Layout>
);
