import { useMutation, useQueryClient } from 'react-query';

import { ClientUserApi } from 'api/user';
import { UserEntity } from 'model/user';
import { CreateUserRequest } from 'api/user/types';
import { noop } from 'lodash';

export const useUsersMutation = (key: string, onSuccess = noop) => {
  const queryClient = useQueryClient();

  const create = useMutation(
    (data: CreateUserRequest) => {
      const api = new ClientUserApi();
      return api.createUser(data);
    },
    {
      onSuccess: (user) => {
        queryClient.setQueryData<UserEntity | undefined>(key, (prevUser) => ({
          ...prevUser,
          ...user,
        }));
        onSuccess(user);
      },
    },
  );

  return {
    create,
  };
};
