import { ClientP2PApi } from 'api/p2p';
import { useQueryBuilder } from 'hooks';
import { noop } from 'lodash';
import { OfferTypes, P2POperation } from 'model/p2p';
import { Query } from 'react-query';

export const useP2PCurrencyPairsQuery = (enabled = true, onSuccess = noop, onError = noop) => {
  const key = 'p2p-currency-pairs';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getCurrencyPairs();

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useP2PPaymentMethodsQuery = (enabled = true, onSuccess = noop, onError = noop) => {
  const key = 'p2p-payment-methods';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getPaymentMethods();

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useP2PUserPaymentMethodsQuery = (enabled = true, onSuccess = noop, onError = noop) => {
  const key = 'p2p-user-payment-methods';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getUserPaymentMethods();

      return res;
    },
    onSuccess,
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useP2POffersListQuery = (type: OfferTypes, currencyPairId: number, enabled = true, onError = noop) => {
  const key = `p2p-offers-list-${type}-${currencyPairId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getOfferListByTypeAndCurrency(type, currencyPairId);

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useP2POffersUserListQuery = (enabled = true, onError = noop) => {
  const key = 'p2p-offers-list';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getOfferUserList();

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useP2POffersExpressQuery = (type: OfferTypes, currencyPairId: number, amount: string, paymentMethodId: number, enabled = true, onError = noop) => {
  const key = `p2p-offers-express-${type}-${currencyPairId}-${amount}-${paymentMethodId}`;

  const props = useQueryBuilder({
    key,
    enabled,
    retry: 0,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getOfferExpress(type, currencyPairId, amount, paymentMethodId);

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useP2POfferOneQuery = (id: number, enabled = true, onSuccess = noop) => {
  const key = `p2p-offers-${id}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getOneOffer(id);

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};

export const useP2POperationOneQuery = (id: number, enabled = true, onSuccess = noop, refetchInterval: number | false | ((data: P2POperation | undefined, query: Query<P2POperation, unknown, P2POperation, string>) => number | false) | undefined = false) => {
  const key = `p2p-operations-${id}`;

  const props = useQueryBuilder({
    key,
    enabled,
    refetchInterval,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getOneOperation(id);

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};

export const useP2POperationListQuery = (enabled = true, onSuccess = noop) => {
  const key = 'p2p-operations-list';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getOperationsList();

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};

export const useP2POperationListExpiredQuery = (enabled = true, onSuccess = noop) => {
  const key = 'p2p-operations-list-expired';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientP2PApi();
      const res = await api.getOperationsListExpired();

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};
