import { Request } from 'lib/request';
import { AxiosInstance } from 'axios';
import {
  CurrencyPairsEntity,
  OfficeHistory,
  OfficeReceipt,
  OfficeOrderRequest,
  OfficeReceiptDetails,
  OfficeRecievingMethod,
} from 'model/office';
import { CountryResponse } from './types';

export class ClientOfficeApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = new Request().apiClient;
  }

  async createOrder(body: OfficeOrderRequest): Promise<any> {
    const response = await this.client.post('/office/operations/create/', body);
    return response.data;
  }

  async statusOrder(
    body: { status: OfficeHistory['status'] } & { operation: number },
  ): Promise<any> {
    const response = await this.client.post(`/office/operations/${body.operation}/status/`, {
      status: body.status,
    });
    return response.data;
  }

  async getCountries(): Promise<CountryResponse[]> {
    const response = await this.client.get('/office/countries/');
    return response.data;
  }

  async getCurrencyPairs(country?: number): Promise<CurrencyPairsEntity[]> {
    const response = await this.client.get(`/office/currency-pairs/?country_id=${country}`);
    return response.data;
  }

  async getOfficeRecievingMethods(
    countryId: number,
    currencyPairId: number,
  ): Promise<OfficeRecievingMethod[]> {
    const response = await this.client.get(
      `/office/receipts/country/${countryId}/currency/${currencyPairId}/`,
    );
    return response.data.data;
  }

  async getOfficeOffer(
    countryId: number,
    currencyPairId: number,
    receiptMethodId: number,
    amount?: string,
  ): Promise<OfficeReceipt> {
    const response = await this.client.get(
      `/office/price/country/${countryId}/currency/${currencyPairId}/receipt/${receiptMethodId}/${
        amount ? `?amount=${amount}` : ''
      }`,
    );
    return response.data.data;
  }

  async getOfficeDetailsMethod(receiptId: number): Promise<OfficeReceiptDetails> {
    const response = await this.client.get(`/office/receipts/${receiptId}/details/last/`);
    return response.data;
  }

  async getOperationsHistory(): Promise<OfficeHistory[]> {
    const response = await this.client.get(`/office/operations/history/`);
    return response.data;
  }

  async getOperationsHistoryOne(operationId: number): Promise<OfficeHistory> {
    const response = await this.client.get(`/office/operations/${operationId}/`);
    return response.data.data;
  }

  async getOperationOfficeOffers(operationId: number): Promise<OfficeHistory[]> {
    const response = await this.client.get(`/office/operations/${operationId}/child/`);
    return response.data.data;
  }
}
