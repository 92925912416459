import { ClientCardsApi } from 'api/cards';
import { CreateCard, TopupCard, WithdrawCard } from 'model/cards';
import { useMutation } from 'react-query';

export const useCardsMutation = () => {
  const createCard = useMutation((data: CreateCard) => {
    const api = new ClientCardsApi();
    return api.createCard(data);
  });

  const topupCard = useMutation((data: TopupCard) => {
    const api = new ClientCardsApi();
    return api.topupCard(data);
  });

  const withdrawCard = useMutation((data: WithdrawCard) => {
    const api = new ClientCardsApi();
    return api.withdrawCard(data);
  });

  const activateCard = useMutation((cardId: string) => {
    const api = new ClientCardsApi();
    return api.activateCard(cardId);
  });

  const freezeCard = useMutation((cardId: string) => {
    const api = new ClientCardsApi();
    return api.freezeCard(cardId);
  });

  const closeCard = useMutation((cardId: string) => {
    const api = new ClientCardsApi();
    return api.closeCard(cardId);
  });

  return {
    createCard,
    topupCard,
    withdrawCard,
    activateCard,
    freezeCard,
    closeCard,
  };
};
