/* eslint-disable @typescript-eslint/naming-convention */
export type NETWORK = 'TRC20' | 'BEP';
export type CRYPTO_CURRENCY = 'USDT';

export type BalanceResponse = {
  success: boolean;
};

export type BalanceInnerSendRequest = {
  from_balance: number;
  to_balance: string;
  amount: number;
};

export type BalanceWithdrawRequest = {
  network: NETWORK;
  user: number;
  external_address: string;
  currency: CRYPTO_CURRENCY;
  amount: number;
};

export type BalanceHistory = {
  operation_id: number;
  amount: string;
  cost: string;
  created_at: string;
  updated_at: string;
  balance_after?: string;
  balance_before?: string;
  fee: string;
  direction: 'topup' | 'withdraw';
  from_balance: number;
  to_balance: string;
  tx_type?:
    | 'internal'
    | 'external'
    | 'inner'
    | 'office_operation_create'
    | 'p2p_operation_create'
    | 'fee_p2p'
    | 'card_fee'
    | 'fee'
    | 'p2p_offer_create'
    | 'p2p_offer_close'
    | 'p2p_operation_complete'
    | 'p2p_offer_refund'
    | 'card_deposit'
    | 'office_operation_refund';
  currency?: CRYPTO_CURRENCY;
  network?: NETWORK;
  tx_id?: string;
};

export type BalanceHistoryOne = {
  id: number;
  amount: string;
  created_at?: string;
  updated_at?: string;
  user?: number;
  currency?: CRYPTO_CURRENCY;
  network?: NETWORK;
  external_address?: string;
  tx_id?: string;
  status: number;
  to_balance?: string;
  type?: 'internal' | 'external' | 'inner' | 'top_up';
  from_balance?: string;
};

export const mapStatus: Record<number, string> = {
  0: 'Created',
  1: 'Done',
};

export const mapType: Record<keyof BalanceHistory['tx_type'], string> = {
  external: 'External operation',
  internal: 'Internal transfer',
  inner: 'Transfer',
  topup: 'Deposit',
  withdraw: 'Withdraw',
  p2p_operation_create: 'P2P Operation',
  fee_p2p: 'P2P Fee',
  p2p_offer_create: 'P2P Offer',
  office_operation_create: 'Office operation',
  card_fee: 'Card fee',
  fee: 'Fee',
  p2p_offer_close: 'P2P offer close',
  p2p_operation_complete: 'P2P operation complete',
  p2p_offer_refund: 'P2P offer refund',
  card_deposit: 'Card deposit',
  office_operation_refund: 'Office operation refund',
};
