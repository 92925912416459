import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { GetOne } from 'components/Cards/GetOne';
import { useParams } from 'react-router-dom';
import { useUserCardQuery, useUserCardTransactionsQuery } from 'queries/cards';
import { Box, CircularProgress } from '@mui/material';

export const CardsGetOnePage: FC = () => {
  const { id } = useParams();
  const { data: card, isLoading, refetch } = useUserCardQuery(id ?? '', !!id);
  const { data: transactions } = useUserCardTransactionsQuery(id ?? '', !!id);

  return (
    <Layout header={<ModuleHeader title="Card" withBack />}>
      {isLoading && (
        <Box display="flex" justifyContent="center" mt="110px">
          <CircularProgress color="primary" size={54} />
        </Box>
      )}
      {card && <GetOne card={card} transactions={transactions ?? []} refetch={refetch} />}
    </Layout>
  );
};
