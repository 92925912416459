/* eslint-disable import/no-extraneous-dependencies */
import React, { FC } from 'react';
// import cn from 'classnames';
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import { useUsersQuery } from 'queries/users';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import {
  useCountriesQuery,
  useCurrencyPairsQuery,
  useOfficeDetailsMethodQuery,
  useOfficeOffersQuery,
  useOfficeRecievingMethodsQuery,
} from 'queries/office';
import useAlert from 'hooks/useAlert';
import { OfficeOrderRequest } from 'model/office';
import { SelectedCard } from 'components/ui/SelectedCard';
import { useOfficeMutation } from 'queries/office/mutation';

export const Cash: FC = () => {
  const navigate = useNavigate();
  const { alert } = useAlert();
  const { data } = useUsersQuery();
  const currentUser = data;
  const schema = yup
    .object({
      country: yup.number().required(),
      currencyPair: yup.number().required(),
      receipt: yup.number().required(),
      details: yup.string().required(),
      amount: yup
        .number()
        .required()
        .min(1)
        .max(currentUser?.free_balance ?? +Infinity),
    })
    .required();
  const { data: countries } = useCountriesQuery();

  const { createOrder } = useOfficeMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<any>({
    defaultValues: {
      country: null,
      buy_amount: null,
      payment: 1,
      currencyPair: null,
      receipt: null,
      offer: null,
      amount: 0,
      details: null,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const selectedCountry = watch('country');
  const selectedCurrencyPair = watch('currencyPair');
  const selectedReceipt = watch('receipt');
  const selectedAmount = watch('amount');
  // const selectedOffer = watch('offer');
  const { data: currencyPairs } = useCurrencyPairsQuery(selectedCountry, !!selectedCountry);
  const selectedRecievedCurrencyObject = currencyPairs?.find((p) => p.id === selectedCurrencyPair);

  const { data: officeRecievingMethods } = useOfficeRecievingMethodsQuery(
    selectedCountry,
    selectedCurrencyPair,
    !!selectedCountry && !!selectedCurrencyPair,
  );

  const { data: officeOffers } = useOfficeOffersQuery(
    {
      countryId: selectedCountry,
      currencyPairId: selectedCurrencyPair,
      receiptMethodId: selectedReceipt,
      amount: selectedAmount,
    },
    !!selectedCountry && !!selectedCurrencyPair && !!selectedReceipt,
  );

  const selectedOfferObject = officeOffers;
  const selectedOfficeRecievingMethodObject = officeRecievingMethods?.find(
    (m) => m.id === selectedReceipt,
  );
  const selectedReceiptAmount = Number(selectedOfferObject?.price ?? 0).toFixed(2);

  useOfficeDetailsMethodQuery(
    selectedReceipt,
    !!selectedReceipt, // && selectedOfferObjectReceiptObject?.is_detail,
    // onUserDetailsFetchSuccess,
  );

  const totalPrice = Number(Number(selectedAmount) * Number(selectedReceiptAmount) + 0).toFixed(2);

  // const renderSelectGroupPaymentMethods = (method: OfficeOffer) => {
  //   const items = method.receipt.map((r) => (
  //     <Box
  //       key={`${method.id}-${r.id}`}
  //       padding="5px"
  //       flexBasis="50%"
  //       minWidth="40vw"
  //       maxWidth="200px"
  //     >
  //       <SelectedCard
  //         sx={{ height: '100%' }}
  //         title={r.name}
  //         text={`Price: ${Number(Number(r.price ?? 0) / Number(method.price ?? 1)).toFixed(
  //           2,
  //         )} USDT`}
  //         isActive={r.id === selectedReceipt && selectedOffer === method.id}
  //         onClick={() => {
  //           setValue('receipt', r.id);
  //           setValue('offer', method.id);
  //         }}
  //       />
  //     </Box>
  //   ));
  //   return [items];
  // };

  const onSuccess = (response: any) => {
    navigate('/success', {
      state: {
        message: 'Order created successfully. You can check order in home page',
        link: response?.data?.id ? `/transaction/office/${response.data.id}` : '/',
      },
    });
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = (data: OfficeOrderRequest) => {
    const requestData: OfficeOrderRequest = {
      sell_amount: (data as any).amount,
      // buy_amount: Number(totalPrice),
      buy_currency: selectedRecievedCurrencyObject?.buy_currency?.name ?? '',
      sell_currency: selectedRecievedCurrencyObject?.sell_currency?.name ?? '',
      // @ts-ignore
      receipt: { ...selectedOfficeRecievingMethodObject, details: data.details },
      payment: data.payment,
    };

    if (Number(selectedAmount) > (currentUser?.free_balance ?? 0)) {
      alert(`${DEFAULT_ERROR_MESSAGE}. Details: not enought balance`, 'error');
      return;
    }

    createOrder.mutate(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  return (
    <div className="pt-90 pb-90">
      <form onSubmit={handleSubmit(onConfirm)}>
        <div className="tf-container">
          <div className="mt-4 accent-box-v2 bg-menuDark">
            <div>
              <h5>Select country and currency you want to recieve</h5>
              <Box display="flex" gap={2}>
                <Box flexBasis="50%">
                  <h6 className="mt-10" style={{ fontSize: '14px' }}>
                    Country
                  </h6>
                  <FormControl sx={{ mt: '10px', width: '100%' }}>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Select country"
                          displayEmpty
                          // renderValue={(value) => value ?? 'Select country'}
                          error={!!errors.country}
                          input={
                            <OutlinedInput
                              placeholder="Select country"
                              sx={{
                                bgcolor: '#2B2B2C',
                              }}
                              inputProps={{
                                sx: {
                                  bgcolor: '#2B2B2C !important',
                                  borderColor: 'transparent !important',
                                  color: '#fff',
                                },
                              }}
                            />
                          }
                        >
                          {countries?.map((country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>
                <Box flexBasis="50%">
                  <h6 className="mt-10" style={{ fontSize: '14px' }}>
                    Currency
                  </h6>
                  <FormControl
                    sx={{ mt: '10px', width: '100%' }}
                    className={!selectedCountry ? 'disabled' : ''}
                  >
                    <Controller
                      name="currencyPair"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Select currency you want to recieve"
                          displayEmpty
                          // renderValue={(value) => value ?? 'Select currency you want to recieve'}
                          error={!!errors.currencyPair}
                          input={
                            <OutlinedInput
                              placeholder="Select currency you want to recieve"
                              sx={{
                                bgcolor: '#2B2B2C',
                              }}
                              inputProps={{
                                sx: {
                                  bgcolor: '#2B2B2C !important',
                                  borderColor: 'transparent !important',
                                  color: '#fff',
                                },
                              }}
                            />
                          }
                        >
                          {currencyPairs?.map((currencyPair) => (
                            <MenuItem key={currencyPair.id} value={currencyPair.id}>
                              {currencyPair.buy_currency.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Divider sx={{ mt: '26px' }} />

              <h5 className="mt-20">Select recieving method</h5>

              <FormControl sx={{ mt: '10px', width: '100%' }}>
                <Box mb="8px">
                  <Box display="flex" margin="-5px" sx={{ overflowX: 'auto' }}>
                    {officeRecievingMethods?.map((method) => (
                      <Box
                        key={`${method.id}`}
                        padding="5px"
                        flexBasis="50%"
                        minWidth="40vw"
                        maxWidth="200px"
                      >
                        <SelectedCard
                          sx={{ height: '100%' }}
                          title={method.name}
                          text=""
                          isActive={method.id === selectedReceipt}
                          onClick={() => {
                            setValue('receipt', method.id);
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>

                {/* {officeOffers?.map((method) => (
                  <Box mb="8px">
                    <Typography sx={{ fontSize: '14px', mb: '8px' }}>
                      Options with price <span className="text-white">{method.price}</span>. Limit
                      from <span className="text-white">{method.limit_from ?? '-'}</span> to{' '}
                      <span className="text-white">{method.limit_to ?? '-'}</span>
                    </Typography>
                    <Box display="flex" margin="-5px" sx={{ overflowX: 'auto' }}>
                      {renderSelectGroupPaymentMethods(method)}
                    </Box>
                  </Box>
                ))} */}

                {!!selectedOfficeRecievingMethodObject && (
                  <>
                    <h6 className="mt-20 mb-10" style={{ fontSize: '14px' }}>
                      Recieving details
                    </h6>

                    <Controller
                      name="details"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          placeholder="Please write down your address or card number or cash nominal you want to receive"
                          multiline
                          error={!!errors.details}
                          sx={{
                            bgcolor: '#2B2B2C',
                            color: '#fff',
                          }}
                          inputProps={{
                            sx: {
                              bgcolor: '#2B2B2C !important',
                              borderColor: 'transparent !important',
                            },
                          }}
                        />
                      )}
                    />
                  </>
                )}

                {/* <Controller
                  name="payment"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select recieving method"
                      displayEmpty
                      error={!!errors.payment}
                      input={(
                        <OutlinedInput
                          placeholder="Select recieving method"
                          className={cn({
                            disabled: selectedCountry && selectedCurrencyPair && (!officePaymentMethods || officePaymentMethods?.length === 0),
                          })}
                          sx={{
                            bgcolor: '#2B2B2C',
                          }}
                          inputProps={{
                            sx: {
                              bgcolor: '#2B2B2C !important',
                              borderColor: 'transparent !important',
                              color: '#fff',
                            },
                          }}
                        />
                      )}
                    >
                      {officePaymentMethods?.map((method) => renderSelectGroupPaymentMethods(method))}
                    </Select>
                  )}
                /> */}

                {(!selectedCountry || !selectedCurrencyPair) && (
                  <FormHelperText sx={{ m: '8px 0 0 0' }}>
                    <Typography sx={{ color: '#f5c738', fontSize: '14px' }}>
                      Choose country and currency for unblock the options
                    </Typography>
                  </FormHelperText>
                )}
                {selectedCountry &&
                  selectedCurrencyPair &&
                  (!officeRecievingMethods || officeRecievingMethods?.length === 0) && (
                    <FormHelperText sx={{ m: '8px 0 0 0' }}>
                      <Typography sx={{ color: '#d33535', fontSize: '14px' }}>
                        No available methods for this region
                      </Typography>
                    </FormHelperText>
                  )}
                {!!errors.receipt && (
                  <FormHelperText sx={{ m: '8px 0 0 0' }}>
                    <Typography sx={{ color: '#d33535', fontSize: '14px' }}>
                      Choose recieving method
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>

              <Divider sx={{ mt: '26px' }} />

              <h5 className="mt-20">Amount</h5>

              <h6 className="mt-10" style={{ fontSize: '14px' }}>
                Send
              </h6>

              <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      // className={cn({
                      //   pointerNone: step !== 0,
                      // })}
                      type="number"
                      error={!!errors.amount}
                      placeholder="Minimum 1"
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>USDT</Typography>
                        </InputAdornment>
                      }
                    />
                  )}
                />

                <FormHelperText
                  sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}
                >
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
                    {currentUser?.free_balance} USDT
                  </Typography>
                </FormHelperText>
              </FormControl>

              <Box color="#7e8088" display="flex" justifyContent="center">
                <SouthIcon color="inherit" fontSize="small" />
              </Box>

              <h6 style={{ fontSize: '14px' }}>Recieve</h6>

              <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                <Controller
                  name="ramount"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      className="pointerNone"
                      type="number"
                      value={selectedAmount * Number(selectedOfferObject?.price ?? 0)}
                      error={!!errors.ramount}
                      sx={{
                        bgcolor: '#2B2B2C',
                      }}
                      inputProps={{
                        sx: {
                          bgcolor: '#2B2B2C !important',
                          borderColor: 'transparent !important',
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>
                            {selectedRecievedCurrencyObject?.buy_currency.name}
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </FormControl>

              {selectedOfferObject && (
                <Typography sx={{ mt: '10px', color: '#fff' }}>
                  <Typography sx={{ fontWeight: 700 }} component="span">
                    1 USDT
                  </Typography>{' '}
                  ={' '}
                  <Typography sx={{ fontWeight: 700 }} component="span">
                    {selectedOfferObject?.price} {selectedRecievedCurrencyObject?.buy_currency.name}
                  </Typography>
                  <Chip
                    sx={{ borderRadius: '4px', ml: '20px' }}
                    label="Best rate"
                    color="primary"
                    size="small"
                  />
                </Typography>
              )}
            </div>

            <Divider sx={{ mt: '16px' }} />

            <div className="content d-flex justify-content-between mt-20">
              <div className="title">
                <h5 className="mb-10">Your order</h5>
                <Typography className="text-secondary text-small mb-4">Transfer amount</Typography>
                <Typography className="text-secondary text-small mb-4">Transfer fee</Typography>
                <Typography className="text-secondary text-small mb-4">
                  Recieving method price
                </Typography>
                <Typography className="text-primary text-small mb-4" sx={{ mt: '10px' }}>
                  Total to reciever
                </Typography>
              </div>
              <div className="box-price mt-32">
                <Typography className="text-small mb-4 text-white">
                  {selectedAmount} USDT
                </Typography>
                <Typography className="text-small mb-4 text-white">0 USDT</Typography>
                <Typography className="text-small mb-4 text-white">
                  {selectedReceiptAmount} USDT
                </Typography>
                <Typography
                  className="text-small mb-4 text-white"
                  sx={{ fontWeight: 700, mt: '10px' }}
                >
                  {totalPrice} {selectedRecievedCurrencyObject?.buy_currency.name}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="tf-container mt-10">
          {/* {step === 0 && <Button className="tf-btn lg primary" onClick={onNextButtonClick}>Next</Button>} */}
          {/* {step === 1 && <Button className="tf-btn lg primary" type="submit">Confirm</Button>} */}
          {/* <LoadingButton loading={innerSend.isLoading} className="tf-btn lg primary" type="submit">Confirm</LoadingButton> */}
          <ConfirmDialog
            title="Confirmation"
            content="The order will be created, please make sure the data entered is correct"
            onConfirm={handleSubmit(onConfirm)}
          >
            <LoadingButton loading={createOrder.isLoading} className="tf-btn lg primary">
              Confirm
            </LoadingButton>
          </ConfirmDialog>
        </div>
      </form>
    </div>
  );
};
