import React, { FC, ReactNode } from 'react';

type ListCardType = {
  icon?: ReactNode;
  title: string;
  description?: string;
};

export const ListCard: FC<ListCardType> = ({ title, description, icon = null }) => (
  <div className="content d-flex justify-content-between">
    {icon}
    <div className="content">
      <div className="title">
        <p className="text-xl text-start">{title}</p>
        {description && <span className="mt-4 text-secondary">{description}</span>}
      </div>
    </div>
  </div>
);
