import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    primary: {
      main: '#25c866',
    },
    secondary: {
      main: '#7e8088',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 'unset',
          padding: '13px 14px',
        },
        icon: {
          top: 0,
          bottom: 0,
          margin: 'auto',
          width: '30px',
          height: '30px',
          color: '#7F8088',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2B2B2C',
        },
      },
    },
  },
});

export default theme;
