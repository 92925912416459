import { Request } from 'lib/request';
import { AxiosInstance } from 'axios';
import {
  Card,
  CardDetails,
  CardTransaction,
  CreateCard,
  LocalCard,
  TopupCard,
  WithdrawCard,
} from 'model/cards';

export class ClientCardsApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = new Request().apiClient;
  }

  async getUserCard(cardId: string): Promise<Card> {
    const response = await this.client.get(`/cards/${cardId}/`);
    return response.data.data;
  }

  async getUserCardDetails(cardId: string): Promise<CardDetails> {
    const response = await this.client.get(`/cards/${cardId}/details/`);
    return response.data.data;
  }

  async getUserCards(): Promise<Array<LocalCard>> {
    const response = await this.client.get(`/cards/`);
    return response.data;
  }

  async getUserCardBalance(cardId: string): Promise<Card['balance']> {
    const response = await this.client.get(`/cards/${cardId}/balance/`);
    return response.data;
  }

  async getUserCardTransactions(cardId: string): Promise<Array<CardTransaction>> {
    const response = await this.client.get(`/cards/${cardId}/transactions/`);
    return response.data.data;
  }

  async createCard(body: CreateCard): Promise<Card> {
    const response = await this.client.post(`/cards/create/`, body);
    return response.data;
  }

  async topupCard(body: TopupCard): Promise<Card> {
    const response = await this.client.post(`/cards/${body.card}/topup/`, body);
    return response.data;
  }

  async withdrawCard(body: WithdrawCard): Promise<Card> {
    const response = await this.client.post(`/cards/withdraw/`, body);
    return response.data;
  }

  async freezeCard(cardId: string): Promise<Card> {
    const response = await this.client.post(`/cards/${cardId}/freeze/`);
    return response.data;
  }

  async activateCard(cardId: string): Promise<Card> {
    const response = await this.client.post(`/cards/${cardId}/activate/`);
    return response.data;
  }

  async closeCard(cardId: string): Promise<Card> {
    const response = await this.client.post(`/cards/${cardId}/close/`);
    return response.data;
  }
}
