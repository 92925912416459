import { Request } from 'lib/request';
import { AxiosInstance } from 'axios';

import { EnvValue } from './types';

export class ClientEnvApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = new Request().apiClient;
  }

  async getEnv(): Promise<EnvValue[]> {
    const response = await this.client.get('/env/');
    return response.data;
  }
}
