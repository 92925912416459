import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

type ModuleHeaderType = {
  title: string;
  withBack?: boolean;
};

export const ModuleHeader: FC<ModuleHeaderType> = ({ title, withBack = false }) => {
  const navigate = useNavigate();
  return (
    <div className="header fixed-top d-flex justify-content-center align-items-center">
      {withBack && <div className="left back-btn" onClick={() => navigate(-1)}><i className="icon-left-btn" /></div>}
      <h3>{title}</h3>
      <div className="right back-btn" onClick={() => navigate('/')}><HomeIcon htmlColor="#fff" /></div>
    </div>
  );
};
