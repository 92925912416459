import React, { FC } from 'react';
import { UserHeader } from 'components/Layout/Header/UserHeader';

type HomeProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
};

export const Layout: FC<HomeProps> = ({ header = null, children }) => (
  <>
    {header ?? <UserHeader />}
    <div>
      {children}
    </div>

    {/* <Footer /> */}
  </>
);
