import React from 'react';
import { CardsList } from 'components/Cards/CardsList';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const Cards = () => (
  <div className="pt-70 pb-90">
    <div className="tf-container">
      <div className="mt-4 text-center">
        <h4 className="mt-40 mb-40">Your issued virtual cards</h4>
      </div>
      <div className="mt-4 accent-box-v2 bg-menuDark">
        <CardsList />
      </div>
      <Box mt="20px">
        <Link to="/cards/create">
          <Button variant="outlined" sx={{ borderStyle: 'dashed', borderRadius: '6px', height: '48px' }}>+ Create new card</Button>
        </Link>
      </Box>
    </div>
  </div>
);
