import { Box, Button, Typography } from '@mui/material';
import { uniq } from 'lodash';
import { P2POffer } from 'model/p2p';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type OrderItemProps = {
  method: 'sell' | 'buy';
  link: string;
  order: P2POffer;
};

export const OrderItem: FC<OrderItemProps> = ({ method, link, order }) => (
  <Box className="accent-box style-1 gap-12 bg-menuDark mb-10">
    <div className="content d-flex justify-content-between">
      <div className="title">
        <Typography className="text-xl" sx={{ color: '#fff', fontWeight: 500 }}>
          {order.price} {order.currency_pair?.quote_currency}
        </Typography>
        <span className="text-secondary text-small">
          Price per 1 {order.currency_pair?.base_currency}
        </span>
      </div>
      <div className="box-price">
        <Link to={link}>
          <Button
            variant="contained"
            className="tf-btn text-white lg"
            sx={{ textTransform: 'capitalize' }}
          >
            {method}
          </Button>
        </Link>
      </div>
    </div>
    <div className="content d-flex justify-content-between mt-10">
      <div className="title" style={{ maxWidth: '200px' }}>
        <Typography className="text-xl" sx={{ color: '#fff', fontWeight: 500 }}>
          {order?.owner?.matextag ?? 'Order info'}
        </Typography>
        <Typography className="text-secondary text-small mb-4">Available</Typography>
        <Typography className="text-secondary text-small mb-4">Limits</Typography>
        <Typography className="text-secondary text-small mb-4">Payment Methods</Typography>
      </div>
      <div className="box-price mt-36">
        <Typography className="text-small mb-4 text-white">
          {order?.total} {order?.currency_pair?.base_currency}
        </Typography>
        <Typography className="text-small mb-4 text-white">
          {order.down_limit ?? 0} - {order.top_limit ?? 0} {order.currency_pair?.quote_currency}
        </Typography>
        <Typography className="text-small mb-4 text-white">
          {uniq([
            ...(order.payment_method?.map((m) => m.name) ?? []),
            ...(order.saved_payment_method?.map((m) => m?.payment_method?.name) ?? []),
          ])?.join(', ')}
        </Typography>
      </div>
    </div>
  </Box>
);
