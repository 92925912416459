import React from 'react';
import { OptionList } from 'components/Cards/Create/OptionList';

export const Create = () => (
  <div className="pt-70 pb-90">
    <div className="tf-container">
      <div className="mt-20 accent-box-v2 bg-menuDark">
        <OptionList />
      </div>
    </div>
  </div>
);
