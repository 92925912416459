import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { MasterCard } from 'components/Icons/MC';
import { Visa } from 'components/Icons/Visa';
import { Card, CardDetails } from 'model/cards';
import { chunk } from 'lodash';

type CardItemProps = {
  card: Card & { details?: CardDetails };
};

const mapIcons = {
  MasterCard: <MasterCard />,
  Visa: <Visa />,
};

export const CardItem: FC<CardItemProps> = ({ card }) => (
  <Box borderRadius="10px" padding="20px" bgcolor="#393737">
    <Box display="flex" justifyContent="space-between" mb="30px">
      <Box>
        <Typography className="text-white" sx={{ fontSize: '14px', fontWeight: 600 }}>
          Card number
        </Typography>
        <Typography className="text-secondary2">
          {chunk(card?.details?.card_number ?? card?.mask_number ?? '', 4)
            .join(' ')
            .replaceAll(',', '')}
        </Typography>
      </Box>
      <Box width="60px" height="40px" display="flex" alignItems="center">
        {mapIcons[card?.bin?.scheme]}
      </Box>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography className="text-white" sx={{ fontSize: '24px', fontWeight: 600 }}>
          $ {card?.balance?.available}
        </Typography>
      </Box>
      <Box>
        <Typography
          className="text-white"
          sx={{ fontSize: '14px', fontWeight: 600, textAlign: 'right' }}
        >
          {card?.details?.expiry_month ?? '**'}/{card?.details?.expiry_year ?? '**'}
        </Typography>
        <Typography className="text-white" sx={{ fontSize: '12px', textAlign: 'right' }}>
          {card.status} {card?.details?.cvv ? `(CVV: ${card?.details?.cvv})` : ''}
        </Typography>
      </Box>
    </Box>
  </Box>
);
