import { Request } from 'lib/request';
import { AxiosInstance } from 'axios';

import { ShortUser } from 'model/user';
import {
  CreateUserRequest, TRC20Response, UserDataResponse, UserResponse,
} from './types';

export class ClientUserApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = new Request().apiClient;
  }

  async createUser(body: CreateUserRequest): Promise<UserResponse> {
    const response = await this.client.post('/users/create/', body);
    return response.data;
  }

  async getUser(): Promise<UserResponse> {
    const response = await this.client.get('/users/');
    return response.data;
  }

  async getUserData(): Promise<UserDataResponse> {
    const response = await this.client.get('/users/data/');
    return response.data;
  }

  async getUserWalletTRC20(): Promise<TRC20Response> {
    const response = await this.client.get('/users/trc20/');
    return response.data;
  }

  async checkUserExists(tag: string): Promise<Partial<UserDataResponse>> {
    const response = await this.client.get(`/users/${tag}/`);
    return response.data.data;
  }

  async userSearch({ tag, tgId, username }: { tag?: string, tgId?: string; username?: string }): Promise<ShortUser[]> {
    const params = new URLSearchParams();
    if (tgId) params.set('tg_id', tgId);
    if (username) params.set('username', username);
    if (tag) params.set('matextag', tag);
    const response = await this.client.get(`/users/search/?${params.toString()}`);
    return response.data;
  }
}
