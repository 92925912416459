/* eslint-disable import/no-extraneous-dependencies */
import CryptoJS from 'crypto-js';

type AuthObject = {
  tgId: number | string;
  auth_date: number | string;
};

export const createAuthHMACSignatureFromObject = (object: AuthObject, privateKey: string) => {
  // Соберем данные для подписи
  const dataToSign = `${object.tgId}${object.auth_date}`;

  const bytedData = CryptoJS.enc.Utf8.parse(dataToSign);
  const bytedKey = CryptoJS.enc.Utf8.parse(privateKey);

  // Создадим HMAC SHA-256 подпись
  const hmacSignature = CryptoJS.HmacSHA256(bytedData, bytedKey);

  // Преобразуем подпись в строку в формате Base64
  const base64Signature = CryptoJS.enc.Base64.stringify(hmacSignature);

  return base64Signature;
};

export const middleElipsis = (str: string, count = 35) => {
  if (str.length > count) {
    return `${str.substr(0, count - 9)}...${str.substr(str.length - 6, str.length)}`;
  }
  return str;
};
