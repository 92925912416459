import React from 'react';

export const BNBChain = () => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_315_1165)">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5 0C33.375 0 43 9.62504 43 21.5C43 33.375 33.375 43 21.5 43C9.62504 43 0 33.375 0 21.5C0 9.62504 9.62504 0 21.5 0Z" fill="#F0B90B" />
      <path d="M11.8164 21.5L11.8319 27.1851L16.6625 30.0276V33.356L9.0049 28.8648V19.8375L11.8164 21.5ZM11.8164 15.8149V19.1277L9.00317 17.4636V14.1507L11.8164 12.4865L14.6435 14.1507L11.8164 15.8149ZM18.6799 14.1507L21.4932 12.4865L24.3202 14.1507L21.4932 15.8149L18.6799 14.1507Z" fill="white" />
      <path d="M13.8492 26.0067V22.6783L16.6625 24.3425V27.6554L13.8492 26.0067ZM18.6799 31.2198L21.4931 32.8839L24.3202 31.2198V34.5326L21.4931 36.1968L18.6799 34.5326V31.2198ZM28.3549 14.1507L31.1681 12.4865L33.9952 14.1507V17.4636L31.1681 19.1277V15.8149L28.3549 14.1507ZM31.1681 27.1851L31.1836 21.5L33.9969 19.8358V28.863L26.3392 33.3543V30.0259L31.1681 27.1851Z" fill="white" />
      <path d="M29.1508 26.0067L26.3375 27.6554V24.3425L29.1508 22.6783V26.0067Z" fill="white" />
      <path d="M29.1508 16.9933L29.1663 20.3216L24.3219 23.1642V28.8631L21.5086 30.5117L18.6954 28.8631V23.1642L13.851 20.3216V16.9933L16.6763 15.3291L21.4914 18.1854L26.3358 15.3291L29.1628 16.9933H29.1508ZM13.8492 11.3099L21.4931 6.80316L29.1508 11.3099L26.3375 12.9741L21.4931 10.1177L16.6625 12.9741L13.8492 11.3099Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_315_1165">
        <rect width="43" height="43" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
