import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { Create } from 'components/P2P/Ads/Create';
import { useUsersQuery } from 'queries/users';

export const P2PAdsCreatePage: FC = () => {
  const { data: user } = useUsersQuery();
  return (
    <Layout header={<ModuleHeader title="Create Ad" withBack />}>
      {user?.is_verified ? <Create /> : null}
    </Layout>
  );
};
