import React from 'react';
import { Box, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { useParams } from 'react-router-dom';
import { useP2POperationOneQuery } from 'queries/p2p';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { useP2PMutation } from 'queries/p2p/mutation';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { LoadingButton } from '@mui/lab';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import useAlert from 'hooks/useAlert';
import { P2PAddOperationMessage, P2PUpdateOperation } from 'model/p2p';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useUsersQuery } from 'queries/users';
import { noop } from 'lodash';
import { Chat } from 'components/Chat';
import Timer from 'components/Timer';
import { LoadingPage } from 'components/LoadingPage';

const mapStatusIcon = {
  new: <AccessTimeFilledOutlinedIcon fontSize="large" />,
  completed: <CheckCircleIcon fontSize="large" color="success" />,
  confirmed_sending: <CheckCircleIcon fontSize="large" color="success" />,
  cancelled: <CancelIcon fontSize="large" color="error" />,
  accepted: null,
};

export const mapStatusText = {
  new: 'Created',
  completed: 'Completed',
  confirmed_sending: 'Funds sent',
  cancelled: 'Cancelled',
  accepted: '',
};

const statusNextFlow = {
  new: 'confirmed_sending',
  confirmed_sending: 'completed',
};

const statusActionTextFlow = {
  new: 'Confirm the sending',
  created: 'Confirm the sending',
  confirmed_sending: 'Confirm the receiving',
};

export const Operation = () => {
  const { alert } = useAlert();
  const { id } = useParams();
  const { data: currentUser } = useUsersQuery();
  const { data, refetch, isLoading } = useP2POperationOneQuery(
    id as unknown as number,
    !!id,
    noop,
    10000,
  );

  const isUserSender = currentUser?.tg_id === data?.seller?.tg_id;
  const isUserBuyer = currentUser?.tg_id === data?.buyer?.tg_id;

  const { updateOperation, addOperationMessage } = useP2PMutation();

  const onSuccess = () => {
    refetch();
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = () => {
    if (!data?.status) {
      return;
    }
    const requestData: P2PUpdateOperation & { id: number } = {
      id: data?.id ?? 0,
      // @ts-ignore
      status: statusNextFlow[data?.status],
    };
    updateOperation.mutate(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  const onCancel = () => {
    if (!data?.status) {
      return;
    }
    const requestData: P2PUpdateOperation & { id: number } = {
      id: data?.id ?? 0,
      // @ts-ignore
      status: 'cancelled',
    };
    updateOperation.mutate(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  const onSendMessage = async (text: string, img?: any) => {
    const requestData: P2PAddOperationMessage = {
      operation: data?.id ?? 0,
      text,
      img,
    };
    return addOperationMessage.mutateAsync(requestData, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  return (
    <LoadingPage progress={isLoading && !data}>
      <div className="pt-70 pb-16">
        <div className="tf-container">
          <div className="mt-4 text-center">
            <h3 className="mt-40">Process operation for</h3>
            <div className="mb-32">
              {data?.base_amount && (
                <h1 className="d-inline-block text-primary">
                  {Number(data?.base_amount).toFixed(2)}
                </h1>
              )}
              <h4 className="d-inline-block text-primary"> {data?.base_currency ?? 'USDT'}</h4>
              <Box mt="10px">
                <h5 className="d-inline-block text-primary">
                  <Timer timestamp={data?.expired_at ?? 0} />
                </h5>
              </Box>
            </div>
            <ul className="mt-12 accent-box-v4 bg-menuDark">
              <li className="d-flex pb-8 line-bt item-check-style3">
                <div className="content d-flex justify-content-between text-white">
                  {data?.status && mapStatusIcon[data?.status]}
                  <div className="content">
                    <div className="title d-flex align-items-start flex-column">
                      <span className="mt-4 text-secondary">Status</span>
                      <p className="text-extra-large">
                        {data?.status && mapStatusText[data?.status]}
                      </p>
                      {isUserBuyer && data?.status === 'new' && (
                        <p
                          className="text-primary"
                          style={{ fontStyle: 'italic', textAlign: 'left' }}
                        >
                          {isUserBuyer &&
                            data?.status === 'new' &&
                            'The seller is waiting for your when you will send funds'}
                        </p>
                      )}
                      {isUserBuyer && data?.status === 'confirmed_sending' && (
                        <p
                          className="text-primary"
                          style={{ fontStyle: 'italic', textAlign: 'left' }}
                        >
                          {isUserBuyer &&
                            data?.status === 'confirmed_sending' &&
                            'Waiting for seller submition'}
                        </p>
                      )}
                      {isUserSender && data?.status === 'new' && (
                        <p
                          className="text-primary"
                          style={{ fontStyle: 'italic', textAlign: 'left' }}
                        >
                          {isUserSender &&
                            data?.status === 'new' &&
                            'Waiting for buyer, he will send you funds'}
                        </p>
                      )}
                      {isUserSender && data?.status === 'confirmed_sending' && (
                        <p
                          className="text-primary"
                          style={{ fontStyle: 'italic', textAlign: 'left' }}
                        >
                          {isUserSender &&
                            data?.status === 'confirmed_sending' &&
                            'Buyer sent you funds and waiting for your submition'}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <li className="d-flex pt-8 item-check-style3">
                <div className="content d-flex justify-content-between text-white">
                  <WarningOutlinedIcon fontSize="large" color="warning" />
                  <div className="content">
                    <div className="title d-flex align-items-start flex-column">
                      <span className="mt-4 text-secondary">Important</span>
                      <p className="text-extra-large text-start">
                        Buyer must confirm the order in 40 minutes after creating
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="mt-12 accent-box-v4 bg-menuDark">
              <li className="d-flex align-items-center justify-content-between pb-8 list-view-custom">
                <span className="text-small">Service fee</span>
                <span className="text-large text-white">{data?.fee} USDT</span>
              </li>
              {data?.seller && (
                <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-t list-view-custom">
                  <span className="text-small">Seller</span>
                  <span className="text-large text-white">{data?.seller?.matextag}</span>
                </li>
              )}
              {data?.buyer && (
                <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-t list-view-custom">
                  <span className="text-small">Buyer</span>
                  <span className="text-large text-white">{data?.buyer?.matextag}</span>
                </li>
              )}
              {data?.base_amount && (
                <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-t list-view-custom">
                  <span className="text-small">Crypto</span>
                  <span className="text-large text-white">
                    {Number(data?.base_amount).toFixed(2)} {data?.base_currency}
                  </span>
                </li>
              )}
              {data?.quote_amount && (
                <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-t list-view-custom">
                  <span className="text-small">Fiat</span>
                  <span className="text-large text-white">
                    {Number(data?.quote_amount).toFixed(2)} {data?.quote_currency}
                  </span>
                </li>
              )}
              {data?.price && (
                <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt line-t list-view-custom">
                  <span className="text-small">Price per 1 {data?.base_currency}</span>
                  <span className="text-large text-white">
                    {Number(data?.price).toFixed(2)} {data?.quote_currency}
                  </span>
                </li>
              )}
              {data?.created_at && (
                <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt list-view-custom">
                  <span className="text-small">Creation date</span>
                  <span className="text-large text-white">
                    {DateTime.fromISO(data?.created_at).toLocaleString(DateTime.DATETIME_MED)}
                  </span>
                </li>
              )}
              <li className="d-flex align-items-center justify-content-between pt-8 list-view-custom">
                <span className="text-small">Time limit</span>
                <span className="text-large text-white">40 minutes</span>
              </li>
            </ul>

            {data?.saved_payment_method && (
              <ul className="mt-12 accent-box-v4 bg-menuDark">
                <li className="d-flex item-check-style3">
                  <div className="content d-flex justify-content-between text-white">
                    <div className="content">
                      <div className="title d-flex align-items-start flex-column">
                        <span className="mt-4 text-secondary">Requisites</span>
                        <p className="text-large text-start">
                          {data?.saved_payment_method?.payment_name
                            ? `${data?.saved_payment_method?.payment_name} `
                            : ''}
                          {data?.saved_payment_method?.payment_method?.name ?? '-'}{' '}
                          {data?.saved_payment_method?.payment_method?.currency?.name ?? '-'}
                        </p>
                        <p className="text-large text-start">
                          {data?.saved_payment_method?.requisites ?? '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <Box mt="12px">
            <Chat
              messages={data?.chat ?? []}
              owner={currentUser?.id}
              onSendMessage={onSendMessage}
            />
          </Box>
          {data?.status &&
            data?.status !== 'cancelled' &&
            data?.status !== 'completed' &&
            ((isUserBuyer && data?.status === 'new') ||
              (isUserSender && data?.status === 'confirmed_sending')) && (
              <Box className="mt-20">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ConfirmDialog
                      title="Confirmation"
                      content="The operation will be submitted, please make sure the data entered is correct"
                      onConfirm={onConfirm}
                    >
                      {/* @ts-ignore */}
                      <LoadingButton
                        loading={updateOperation.isLoading}
                        variant="contained"
                        className="tf-btn text-white lg text-xl"
                      >
                        {/* @ts-ignore */}
                        {statusActionTextFlow[data?.status]}
                      </LoadingButton>
                    </ConfirmDialog>
                  </Grid>
                </Grid>
              </Box>
            )}
          {data?.status && data?.status === 'new' && isUserBuyer && (
            <Box className="mt-20">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ConfirmDialog
                    title="Confirmation"
                    content="The operation will be canceled"
                    onConfirm={onCancel}
                  >
                    {/* @ts-ignore */}
                    <LoadingButton
                      loading={updateOperation.isLoading}
                      variant="outlined"
                      className="tf-btn text-white lg text-xl"
                    >
                      Cancel operation
                    </LoadingButton>
                  </ConfirmDialog>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </div>
    </LoadingPage>
  );
};
