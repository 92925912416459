import { useMutation } from 'react-query';
import { ClientOfficeApi } from 'api/office';
import { OfficeHistory, OfficeOrderRequest } from 'model/office';

export const useOfficeMutation = () => {
  const createOrder = useMutation((data: OfficeOrderRequest) => {
    const api = new ClientOfficeApi();
    return api.createOrder(data);
  });

  const statusOrder = useMutation(
    (data: { status: OfficeHistory['status'] } & { operation: number }) => {
      const api = new ClientOfficeApi();
      return api.statusOrder(data);
    },
  );

  return {
    createOrder,
    statusOrder,
  };
};
