import React, { FC } from 'react';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DateTime } from 'luxon';
import { BalanceHistory, BalanceHistoryOne, mapStatus, mapType } from 'api/balance/types';
import { middleElipsis } from 'utils';
import { Box, Button } from '@mui/material';
import useAlert from 'hooks/useAlert';

type TransactionViewProps = {
  transaction?: Partial<BalanceHistoryOne> & BalanceHistory;
};

export const TransactionView: FC<TransactionViewProps> = ({ transaction }) => {
  const { alert } = useAlert();
  const onCopy = (tag?: string | null) => {
    if (!tag) {
      return;
    }
    navigator.clipboard.writeText(tag);
    alert('Copied!', 'success');
  };

  return (
    <div className="pt-70 pb-16">
      <div className="tf-container">
        <div className="mt-4 text-center">
          <h3 className="mt-40">Transaction amount</h3>
          <div className="mb-32">
            {transaction?.amount && (
              <h1 className="d-inline-block text-primary">
                {Number(transaction?.amount).toFixed(2)}
              </h1>
            )}
            <h4 className="d-inline-block text-primary"> {transaction?.currency ?? 'USDT'}</h4>
          </div>
          <ul className="mt-12 accent-box-v4 bg-menuDark">
            <li className="d-flex item-check-style3">
              <div className="content d-flex justify-content-between text-white">
                {((transaction?.status && transaction?.status !== 1) ||
                  (typeof transaction?.status === 'number' && transaction?.status !== 1) ||
                  (typeof transaction?.status === 'string' &&
                    transaction?.status !== 'COMPLETION')) && (
                  <AccessTimeFilledOutlinedIcon fontSize="large" />
                )}
                {(transaction?.status === 1 ||
                  (typeof transaction?.status === 'number' && transaction?.status === 1) ||
                  (typeof transaction?.status === 'string' &&
                    transaction?.status === 'COMPLETION')) && (
                  <CheckCircleIcon fontSize="large" color="success" />
                )}
                <div className="content">
                  <div className="title d-flex align-items-start flex-column">
                    <span className="mt-4 text-secondary">Status</span>
                    {/* @ts-ignore */}
                    {typeof transaction?.status !== 'undefined' && (
                      <p className="text-extra-large">
                        {/* @ts-ignore */}
                        {mapStatus[transaction?.status]}
                      </p>
                    )}
                    {typeof transaction?.status === 'undefined' && (
                      <p className="text-extra-large">Processed</p>
                    )}
                  </div>
                </div>
              </div>
            </li>
            {/* <li className="d-flex pt-8 item-check-style3">
            <div className="content d-flex justify-content-between text-white">
              <WarningOutlinedIcon fontSize="large" />
              <div className="content">
                <div className="title d-flex align-items-start flex-column">
                  <span className="mt-4 text-secondary">Important</span>
                  <p className="text-extra-large text-start">Buyer must confirm the order in 40 minutes after creating</p>
                </div>
              </div>
            </div>
          </li> */}
          </ul>

          <ul className="mt-12 accent-box-v4 bg-menuDark">
            <li className="d-flex align-items-center justify-content-between pb-8 list-view-custom">
              <span className="text-small">Service fee</span>
              <span className="text-large text-white">{transaction?.fee ?? 0} USDT</span>
            </li>
            {/* {transaction?.to_balance && (
              <li
                className="d-flex align-items-center justify-content-between pt-8 pb-8 line-t"
                style={{ flexWrap: 'wrap' }}
              >
                <span className="text-small">Destination</span>
                <span className="text-large text-white">{transaction?.to_balance}</span>
              </li>
            )} */}
            {transaction?.to_balance && (
              <li
                className="d-flex align-items-center justify-content-between pt-8 line-t list-view-custom"
                style={{ flexWrap: 'wrap' }}
              >
                <span className="text-small">Destination</span>
                <span className="text-large text-white">{transaction?.to_balance}</span>
              </li>
            )}
            {transaction?.type && (
              <li
                className="d-flex align-items-center justify-content-between pt-8 line-t list-view-custom"
                style={{ flexWrap: 'wrap' }}
              >
                <span className="text-small">Type</span>
                <span className="text-large text-white">
                  {/* @ts-ignore */}
                  {mapType[transaction?.type] ?? transaction?.type}
                </span>
              </li>
            )}
            {/* {transaction?.received && (
              <li className="d-flex align-items-center justify-content-between pt-8 line-t">
                <span className="text-small">Total write-off amount</span>
                <span className="text-large text-white">
                  {Number(transaction?.received).toFixed(2)} {transaction?.received_currency}
                </span>
              </li>
            )} */}
          </ul>

          <ul className="mt-12 accent-box-v4 bg-menuDark">
            {/* {transaction?.price && (
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt">
                <span className="text-small">Price per 1 USDT</span>
                <span className="text-large text-white">
                  {Number(transaction?.price).toFixed(2)} {transaction?.given_currency}
                </span>
              </li>
            )} */}
            {transaction?.network && (
              <li className="d-flex align-items-center justify-content-between pb-8 pt-8 line-bt list-view-custom">
                <span className="text-small">Payment method</span>
                <span className="text-large text-white">{transaction?.network ?? 'Transfer'}</span>
              </li>
            )}
            {transaction?.external_address && (
              <li className="d-flex align-items-center justify-content-between pb-8 pt-8 line-bt list-view-custom">
                <span className="text-small">Address</span>
                <span className="text-large text-white">
                  {middleElipsis(transaction?.external_address ?? '', 22)}
                </span>
              </li>
            )}
            {/* {transaction?.receipt && (
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt">
                <span className="text-small">Recieving method</span>
                <span className="text-large text-white">
                  {transaction?.receipt?.receipt_method_data?.name}
                </span>
              </li>
            )}
            {transaction?.receipt && (
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt">
                <span className="text-small">Recieving method price</span>
                <span className="text-large text-white">
                  {transaction?.receipt?.receipt_method_data?.price}{' '}
                  {transaction?.receipt?.receipt_method_data?.price_currency?.name}
                </span>
              </li>
            )} */}
            {/* {transaction?.receipt?.details && (
              <li className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt">
                <span className="text-small">Recieving details</span>
                <span className="text-large text-white">{transaction?.receipt?.details}</span>
              </li>
            )} */}
            {transaction?.tx_id && (
              <li
                className="d-flex align-items-center justify-content-between pt-8 pb-8 line-bt list-view-custom"
                style={{ flexWrap: 'wrap' }}
              >
                <span className="text-small">Crypto transaction</span>
                <a
                  href={`https://${
                    process.env.REACT_APP_ENV !== 'production' ? 'shasta.' : ''
                  }tronscan.org/#/transaction/${transaction?.tx_id}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textAlign: 'left' }}
                >
                  <span className="text-large text-primary" style={{ wordBreak: 'break-all' }}>
                    {middleElipsis(transaction?.tx_id ?? '')}
                  </span>
                </a>
                <Box sx={{ display: 'flex', mt: '8px' }}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ mr: '10px' }}
                    onClick={() => {
                      onCopy(transaction?.tx_id);
                    }}
                  >
                    Copy
                  </Button>
                  <Button size="small" variant="outlined">
                    <a
                      href={`https://${
                        process.env.REACT_APP_ENV !== 'production' ? 'shasta.' : ''
                      }tronscan.org/#/transaction/${transaction?.tx_id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary"
                    >
                      Explore
                    </a>
                  </Button>
                </Box>
              </li>
            )}
            {transaction?.created_at && (
              <li className="d-flex align-items-center justify-content-between pt-8 list-view-custom">
                <span className="text-small">Creation date</span>
                <span className="text-large text-white">
                  {DateTime.fromISO(transaction?.created_at).toLocaleString(DateTime.DATETIME_MED)}
                </span>
              </li>
            )}
            {/* {transaction?.created_at && (
              <li className="d-flex align-items-center justify-content-between pt-8">
                <span className="text-small">Creation date</span>
                <span className="text-large text-white">
                  {DateTime.fromISO(transaction?.created_at).toLocaleString(DateTime.DATETIME_MED)}
                </span>
              </li>
            )} */}
          </ul>

          {/* <ul className="mt-12 accent-box-v4 bg-menuDark">
          <li className="d-flex item-check-style3">
            <div className="content d-flex justify-content-between text-white">
              <ChatBubbleOutlineOutlinedIcon />
              <div className="content">
                <div className="title d-flex align-items-start flex-column">
                  <span className="mt-4 text-secondary">Comment</span>
                  <p className="text-large text-start">Some comment from user</p>
                </div>
              </div>
            </div>
          </li>
        </ul> */}
        </div>
        {/* <Box className="mt-20">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button variant="contained" className="tf-btn secondary lg text-xl">Create order</Button>
          </Grid>
        </Grid>
      </Box> */}
      </div>
    </div>
  );
};
