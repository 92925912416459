import React, { FC, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { P2PAddOperationMessage, P2POperationMessage } from 'model/p2p';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type ChatProps = {
  messages: Array<P2POperationMessage>;
  owner?: number;
  onSendMessage: (text: string, img?: any) => Promise<P2PAddOperationMessage>;
};

export const Chat: FC<ChatProps> = ({ messages, onSendMessage, owner = null }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      text: '',
      img: null,
    },
  });

  const formText = watch('text');
  const formImg = watch('img');

  const onConfirm = async (fields: { text: string; img: File | null }) => {
    const result = await onSendMessage(fields.text, fields.img);
    if (result) {
      reset();
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current?.scrollTo({ top: containerRef.current?.scrollHeight ?? 0 + 20 });
    }
  }, [messages]);

  return (
    <Box className="accent-box-v4 bg-menuDark">
      <form onSubmit={handleSubmit(onConfirm)}>
        <h5>Chat</h5>

        <Box
          ref={containerRef}
          sx={{
            overflowY: 'auto',
            maxHeight: '300px',
            minHeight: '200px',
            mt: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ flex: '1 1 auto' }} />
          {messages.length === 0 && (
            <Typography sx={{ fontSize: '12px', marginTop: 'auto' }}>
              No messages yet... <br /> Write down your first message
            </Typography>
          )}
          {messages.map((message) => {
            const isUserMessage = message.user === owner;
            return (
              <Box
                key={`${message.created_at}-${message.text}`}
                sx={{
                  display: 'flex',
                  justifyContent: !isUserMessage ? 'flex-start' : 'flex-end',
                  mb: 1,
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: '4px 8px',
                    borderRadius: '8px',
                    backgroundColor: isUserMessage ? '#198754' : '#393737',
                  }}
                >
                  <Typography className="text-white" sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {message.text}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: 400,
                      color: !isUserMessage ? '#7e8088' : 'rgba(255, 255, 255, 0.7)',
                    }}
                  >
                    {DateTime.fromISO(message?.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
                  </Typography>
                </Paper>
              </Box>
            );
          })}
        </Box>

        <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                {...field}
                placeholder="Write your message"
                multiline
                error={!!errors.text}
                sx={{
                  bgcolor: '#2B2B2C',
                  color: '#fff',
                  alignItems: 'flex-start',
                }}
                inputProps={{
                  sx: {
                    bgcolor: '#2B2B2C !important',
                    borderColor: 'transparent !important',
                  },
                }}
                endAdornment={
                  <InputAdornment position="end" sx={{ paddingTop: '12px' }}>
                    <Controller
                      control={control}
                      name="img"
                      render={({ field: { value, onChange, ...field } }) => (
                        <IconButton
                          aria-label="upload"
                          component="label" // THIS IS THE GENIUS CHANGE
                        >
                          <AttachFileIcon color="primary" />
                          <input
                            {...field}
                            hidden
                            value={value?.img}
                            type="file"
                            onChange={(event) => {
                              // @ts-ignore
                              if (event.target.files?.[0]) {
                                // @ts-ignore
                                onChange(event.target.files?.[0]);
                              }
                            }}
                          />
                        </IconButton>
                      )}
                    />

                    <IconButton
                      sx={{
                        padding: 0,
                        width: 'auto',
                        ml: '4px',
                        opacity: formText.trim() === '' && !formImg ? '0.5' : '1',
                      }}
                      type="submit"
                      disabled={formText.trim() === '' && !formImg}
                    >
                      <SendOutlinedIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </FormControl>
        {formImg && <Box mt="6px">Attached file: {formImg.name}</Box>}
      </form>
    </Box>
  );
};
