import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useP2PCurrencyPairsQuery,
  useP2PPaymentMethodsQuery,
  useP2PUserPaymentMethodsQuery,
} from 'queries/p2p';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { LoadingButton } from '@mui/lab';
import { useP2PMutation } from 'queries/p2p/mutation';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import useAlert from 'hooks/useAlert';
import { P2PCreateOffer } from 'model/p2p';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUsersQuery } from 'queries/users';
import { ManagePaymentMethods } from 'components/P2P/ManagePaymentMethods';
import { useEnvQuery } from 'queries/env';

const TYPE = [
  {
    id: 'buy',
    name: 'Buy',
  },
  {
    id: 'sell',
    name: 'Sell',
  },
];

const ENV_ENAM = 'P2P_FEE';

export const Create: FC = () => {
  const { alert } = useAlert();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { createOffer } = useP2PMutation();
  const { data } = useP2PCurrencyPairsQuery();
  const { data: paymentMethods } = useP2PPaymentMethodsQuery();
  const { data: userPaymentMethods } = useP2PUserPaymentMethodsQuery();
  const { data: currentUser } = useUsersQuery();
  const { data: env } = useEnvQuery();
  const operationFee = env?.find((e) => e.key === ENV_ENAM)?.value;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const schema = yup.object({
    currency_pair: yup.number().required(),
    price: yup.number().required().min(1),
    down_limit: yup.number().required().min(1),
    top_limit: yup.number().required().min(1),
    total: yup
      .number()
      .required()
      .min(1)
      .max(currentUser?.free_balance ?? +Infinity),
    comment: yup.string().max(400),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      type: 'sell',
      currency_pair: null,
      price: '',
      total: '',
      down_limit: '',
      top_limit: '',
      payment_method: [],
      saved_payment_method: [],
      comment: '',
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const formCurrencyPair = watch('currency_pair');
  const formPaymentMethods = watch('payment_method');
  const formUserPaymentMethods = watch('saved_payment_method');
  const formType = watch('type');
  const formPrice = watch('price');
  const selectedCurrencyPair = data?.find((c) => c.id === formCurrencyPair);

  const onSuccess = () => {
    navigate('/success', {
      state: {
        message:
          'P2P creating operation completed successfully. You can check order status in home or p2p page',
        link: '/p2p/ads',
      },
    });
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = (request: P2PCreateOffer) => {
    createOffer.mutate(
      {
        ...request,
        payment_method: formType === 'buy' ? formPaymentMethods : [],
        saved_payment_method: formType === 'sell' ? formUserPaymentMethods : [],
      },
      {
        onSuccess,
        // @ts-ignore
        onError,
      },
    );
  };

  useEffect(() => {
    setValue('payment_method', []);
    setValue('saved_payment_method', []);
  }, [formCurrencyPair]);

  return (
    <div className="pt-90 pb-90">
      <div className="tf-container">
        <div className="mt-4 accent-box-v2 bg-menuDark">
          <form onSubmit={handleSubmit(onConfirm)}>
            <h5>I want to</h5>

            <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <ToggleButtonGroup {...field} size="small" color="primary" exclusive>
                    {TYPE.map((type) => (
                      <ToggleButton
                        key={type.id}
                        value={type.id}
                        sx={{ color: '#fff', borderColor: 'rgba(37, 200, 102, 0.08)' }}
                      >
                        {type.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              />
            </FormControl>

            {/* <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
              <OutlinedInput
                className={cn({
                  // pointerNone: step !== 0,
                })}
                type="number"
                placeholder="Minimum 1"
                sx={{
                  bgcolor: '#2B2B2C',
                }}
                inputProps={{
                  sx: {
                    bgcolor: '#2B2B2C !important',
                    borderColor: 'transparent !important',
                  },
                }}
                endAdornment={<InputAdornment position="end"><Typography sx={{ color: '#7F8088', fontWeight: 500 }}>USDT</Typography></InputAdornment>}
              />
              <FormHelperText sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}>
                <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
                <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>72.052,84752067 USDT</Typography>
              </FormHelperText>
            </FormControl> */}

            <Box display="flex" gap={2}>
              <Box flexBasis="50%">
                <h5 className="mt-20">Fiat currency</h5>

                <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                  <Controller
                    name="currency_pair"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        displayEmpty
                        error={!!errors.currency_pair}
                        input={
                          <OutlinedInput
                            sx={{
                              bgcolor: '#2B2B2C',
                            }}
                            inputProps={{
                              sx: {
                                bgcolor: '#2B2B2C !important',
                                borderColor: 'transparent !important',
                                color: '#fff',
                              },
                            }}
                          />
                        }
                      >
                        {data?.map((currency) => (
                          <MenuItem key={currency.id} value={currency.id}>
                            {currency.quote_currency}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
              <Box flexBasis="50%">
                <h5 className="mt-20">Crypto currency</h5>

                <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
                  <Typography sx={{ color: '#fff', mt: '10px', fontSize: '18px' }}>USDT</Typography>
                </FormControl>
              </Box>
            </Box>

            <Divider sx={{ mt: '16px' }} />

            <h5 className="mt-16">Limits per operation</h5>

            <h6 className="mt-10" style={{ fontSize: '14px' }}>
              From
            </h6>

            <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
              <Controller
                name="down_limit"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    type="number"
                    error={!!errors.down_limit}
                    placeholder="Minimum 1"
                    sx={{
                      bgcolor: '#2B2B2C',
                    }}
                    inputProps={{
                      sx: {
                        bgcolor: '#2B2B2C !important',
                        borderColor: 'transparent !important',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>
                          {selectedCurrencyPair?.quote_currency ?? 'USDT'}
                        </Typography>
                      </InputAdornment>
                    }
                  />
                )}
              />

              {/* <FormHelperText sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}>
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
                  <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
                    {currentUser?.free_balance}
                    {' '}
                    USDT
                  </Typography>
                </FormHelperText> */}
            </FormControl>

            <h6 className="mt-10" style={{ fontSize: '14px' }}>
              To
            </h6>

            <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
              <Controller
                name="top_limit"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    type="number"
                    placeholder="Minimum 1"
                    error={!!errors.top_limit}
                    sx={{
                      bgcolor: '#2B2B2C',
                    }}
                    inputProps={{
                      sx: {
                        bgcolor: '#2B2B2C !important',
                        borderColor: 'transparent !important',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>
                          {selectedCurrencyPair?.quote_currency ?? 'USDT'}
                        </Typography>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </FormControl>

            <h5 className="mt-20">Total for offer</h5>

            <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
              <Controller
                name="total"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    type="number"
                    placeholder="Minimum 1"
                    error={!!errors.total}
                    sx={{
                      bgcolor: '#2B2B2C',
                    }}
                    inputProps={{
                      sx: {
                        bgcolor: '#2B2B2C !important',
                        borderColor: 'transparent !important',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>
                          {selectedCurrencyPair?.base_currency ?? 'USDT'}
                        </Typography>
                      </InputAdornment>
                    }
                  />
                )}
              />

              <FormHelperText
                sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}
              >
                <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>Available</Typography>
                <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
                  {currentUser?.free_balance} USDT
                </Typography>
              </FormHelperText>
            </FormControl>

            <Divider sx={{ mt: '16px' }} />

            <h5 className="mt-16">Price</h5>

            <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    type="number"
                    placeholder="Minimum 1"
                    error={!!errors.price}
                    sx={{
                      bgcolor: '#2B2B2C',
                    }}
                    inputProps={{
                      sx: {
                        bgcolor: '#2B2B2C !important',
                        borderColor: 'transparent !important',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography sx={{ color: '#7F8088', fontWeight: 500 }}>
                          Per 1 {selectedCurrencyPair?.base_currency ?? 'USDT'}
                        </Typography>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <FormHelperText
                sx={{ display: 'flex', justifyContent: 'space-between', m: '8px 0 0 0' }}
              >
                <Typography sx={{ color: '#7F8088', fontSize: '14px' }}>
                  Operation fee: {(Number(formPrice) / 100) * Number(operationFee ?? 0)} USDT
                </Typography>
              </FormHelperText>
            </FormControl>

            <h5 className="mt-20">Payment method</h5>

            <FormControl sx={{ mt: '10px', width: '100%' }} variant="outlined">
              {formType === 'buy' && (
                <Controller
                  name="payment_method"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      multiple
                      error={!!errors.payment_method}
                      input={
                        <OutlinedInput
                          sx={{
                            bgcolor: '#2B2B2C',
                          }}
                          inputProps={{
                            sx: {
                              bgcolor: '#2B2B2C !important',
                              borderColor: 'transparent !important',
                              color: '#fff',
                            },
                          }}
                        />
                      }
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Typography component="span" sx={{ color: '#7F8088' }}>
                              Selected payment methods
                            </Typography>
                          );
                        }

                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {/* @ts-ignore */}
                            {selected?.map((value) => (
                              <Chip
                                key={value}
                                label={paymentMethods?.find((m) => m.id === value)?.name ?? value}
                                sx={{ bgcolor: 'rgba(37, 200, 102, 0.5)', color: '#fff' }}
                                onDelete={() => {
                                  setValue(
                                    'payment_method',
                                    formPaymentMethods.filter((id: number) => id !== value),
                                  );
                                }}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                            ))}
                          </Box>
                        );
                      }}
                    >
                      {paymentMethods
                        ?.filter((m) => m.currency?.name === selectedCurrencyPair?.quote_currency)
                        ?.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              )}
              {formType === 'sell' && (
                <>
                  <Controller
                    name="saved_payment_method"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        displayEmpty
                        multiple
                        error={!!errors.saved_payment_method}
                        input={
                          <OutlinedInput
                            sx={{
                              bgcolor: '#2B2B2C',
                            }}
                            inputProps={{
                              sx: {
                                bgcolor: '#2B2B2C !important',
                                borderColor: 'transparent !important',
                                color: '#fff',
                              },
                            }}
                          />
                        }
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <Typography component="span" sx={{ color: '#7F8088' }}>
                                Selected payment methods
                              </Typography>
                            );
                          }

                          return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {/* @ts-ignore */}
                              {selected?.map((value) => (
                                <Chip
                                  key={value}
                                  label={
                                    userPaymentMethods?.find((m) => m.id === value)?.name ?? value
                                  }
                                  sx={{ bgcolor: 'rgba(37, 200, 102, 0.5)', color: '#fff' }}
                                  onDelete={() => {
                                    setValue(
                                      'saved_payment_method',
                                      formUserPaymentMethods.filter((id: number) => id !== value),
                                    );
                                  }}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                />
                              ))}
                            </Box>
                          );
                        }}
                      >
                        {userPaymentMethods
                          ?.filter(
                            (m) =>
                              m.payment_method?.currency?.name ===
                              selectedCurrencyPair?.quote_currency,
                          )
                          ?.map((value) => (
                            <MenuItem key={value.id} value={value.id}>
                              {value.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  <FormHelperText
                    sx={{ display: 'flex', justifyContent: 'flex-end', m: '8px 0 0 0' }}
                  >
                    <Typography
                      sx={{ fontSize: '18px', color: '#25c866' }}
                      onClick={toggleDrawer(true)}
                    >
                      Add
                    </Typography>
                  </FormHelperText>
                </>
              )}
            </FormControl>

            <h5 className="mt-10">Comment</h5>

            <FormControl sx={{ width: '100%', mt: '10px' }} variant="outlined">
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    placeholder="Please write down your comment"
                    multiline
                    error={!!errors.comment}
                    sx={{
                      bgcolor: '#2B2B2C',
                      color: '#fff',
                    }}
                    inputProps={{
                      sx: {
                        bgcolor: '#2B2B2C !important',
                        borderColor: 'transparent !important',
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            <Box mt="20px">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ConfirmDialog
                    title="Confirmation"
                    content={`The offer will be created, please make sure the data entered is correct. Operation fee is ${
                      operationFee ?? 0
                    }%`}
                    onConfirm={handleSubmit(onConfirm)}
                  >
                    <LoadingButton
                      loading={createOffer.isLoading}
                      variant="contained"
                      className="tf-btn text-white lg text-xl"
                    >
                      Create
                    </LoadingButton>
                  </ConfirmDialog>
                </Grid>
              </Grid>
            </Box>
          </form>

          <SwipeableDrawer
            anchor="right"
            PaperProps={{
              sx: {
                width: '100%',
              },
            }}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <div className="modal mRight">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
                    <span
                      className="left"
                      data-bs-dismiss="modal"
                      aria-hidden="true"
                      onClick={toggleDrawer(false)}
                    >
                      <i className="icon-left-btn" />
                    </span>
                    <h3>Manage payment methods</h3>
                  </div>
                  <div className="overflow-auto pt-70 pb-16">
                    <div className="tf-container">
                      <ManagePaymentMethods
                        onSubmitSuccess={() => {
                          setDrawerOpen(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        </div>
      </div>
    </div>
  );
};
