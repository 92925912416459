export type OfficeCountryEntity = {
  id: number;
  name: string;
};

export type OfficeOrderStatuses =
  | 'CREATED'
  | 'COLLECTING_PARCEL'
  | 'PICK_UP_READY'
  | 'DELIVERING'
  | 'COMPLETION'
  | 'CANCELLED';

export type Currency = {
  id: number;
  name: string;
};

export type CurrencyPairsEntity = {
  id: number;
  sell_currency: Currency;
  buy_currency: Currency;
};

export type PaymentMethod = {
  id: number;
  name: string;
};

export type ReceiptMethodData = {
  id: number;
  price: string;
  is_detail: boolean;
  name: string;
  price_currency: Currency;
};

export type OfficeRecievingMethod = {
  id: number;
  name: string;
  details?: string;
};

export type OfficeReceipt = {
  // id: number;
  // payment: Array<PaymentMethod>;
  // receipt: Array<ReceiptMethodData>;
  // limit_from: number;
  // limit_to: number;
  price: string;
  // base_currency: number;
};

export type OfficeReceiptDetails = {
  receipt_id: number;
  details: string;
};

export type OfficeOrderRequest = {
  buy_amount: number; // сумма которую получит клиент
  sell_amount: number; // сумма которую заплатит клиент
  buy_currency: string;
  sell_currency: string;
  receipt?: OfficeRecievingMethod;
  payment: number; // пока всегда id 1 "С баланса"
  details?: string;
};

export type OfficeHistory = {
  buy_amount: number | null;
  buy_currency: string;
  child_operation: number | null;
  created_at: string;
  external_user_id: number;
  id: number;
  office: number | null;
  parent_operation: number | null;
  payment: string;
  price: number | null;
  receipt: {
    id: number;
    receipt_method: string;
    details: string;
  };
  sell_amount: number | null;
  sell_currency: string;
  status:
    | 'CREATED'
    | 'COLLECTING_PARCEL'
    | 'CONFIRMED_BY_CLIENT'
    | 'COMPLETION_CLIENT'
    | 'CANCELLED_CLIENT'
    | 'CANCELLED'
    | 'COMPLETION';
};

export const mapStatus: Record<OfficeHistory['status'], string> = {
  CREATED: 'Created',
  COLLECTING_PARCEL: 'Office confirmed',
  CONFIRMED_BY_CLIENT: 'Order confirmed',
  COMPLETION_CLIENT: 'Completed',
  COMPLETION: 'Completed',
  CANCELLED_CLIENT: 'Cancelled',
  CANCELLED: 'Cancelled',
};
