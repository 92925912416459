/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useState } from 'react';
import { AccountInfo } from 'components/AccountInfo';
import cn from 'classnames';
import { useBalanceHistoryQuery } from 'queries/balance/query';
import { DateTime } from 'luxon';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useOfficeHistoryQuery } from 'queries/office';
import { Box, Chip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { mapType } from 'api/balance/types';
import { mapStatus } from 'model/office';

const TABS = [
  {
    key: 0,
    title: 'Transactions',
  },
  {
    key: 1,
    title: 'Cash orders',
  },
];

export const Wallet: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { data: balanceHistory } = useBalanceHistoryQuery(activeTab === 0);
  const { data: officeHistory } = useOfficeHistoryQuery(activeTab === 1);

  const handleChangeTabs = (newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className="pt-68 pb-80">
      <AccountInfo />
      {/* <LatestRecipient /> */}
      <div className="bg-menuDark tf-container">
        <div className="tf-tab pt-12 mt-4">
          <h4 className="mb-10">History</h4>
          <div className="tab-slide">
            <ul className="nav nav-tabs wallet-tabs" role="tablist">
              <li className="item-slide-effect" />
              {TABS.map((tab) => (
                <li
                  key={tab.key}
                  className={cn({
                    'nav-item': true,
                    active: activeTab === tab.key,
                  })}
                  role="presentation"
                  onClick={() => handleChangeTabs(tab.key)}
                >
                  <button
                    type="button"
                    className={cn({
                      'nav-link': true,
                      active: activeTab === tab.key,
                    })}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.title}`}
                  >
                    {tab.title}
                  </button>
                </li>
              ))}
              {/* <li
                className={cn({
                  'nav-item': true,
                  active: activeTab === 0,
                })}
                role="presentation"
                onClick={() => handleChangeTabs(0)}
              >
                <button type="button" className="nav-link active" data-bs-toggle="tab" data-bs-target="#history">History</button>
              </li>
              <li
                className={cn({
                  'nav-item': true,
                  active: activeTab === 1,
                })}
                role="presentation"
                onClick={() => handleChangeTabs(1)}
              >
                <button type="button" className="nav-link" data-bs-toggle="tab" data-bs-target="#market">Market</button>
              </li> */}
            </ul>
          </div>
          <div className="tab-content pt-16 pb-16">
            <div
              className={`tab-pane fade ${activeTab === 0 ? 'active show' : ''}`}
              id="history"
              role="tabpanel"
            >
              {balanceHistory && balanceHistory.length !== 0 && (
                <ul>
                  {balanceHistory?.map((balanceHistory, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={idx} className={idx !== 0 ? 'mt-8' : ''}>
                      <Link
                        to={`/transaction/balance/${balanceHistory.operation_id}?type=${balanceHistory.tx_type}`}
                        state={{
                          entity: balanceHistory,
                        }}
                        className="coin-item style-1 gap-12 bg-surface"
                      >
                        {/* <img src="images/coin/coin-1.jpg" alt="img" className="img" /> */}
                        <MonetizationOnIcon className="img" fontSize="small" />
                        <div className="content">
                          <div className="title">
                            <p className="mb-4 text-large">
                              {/* @ts-ignore */}
                              {/* {mapType[balanceHistory.direction]}{' '} */}
                              <Chip
                                sx={{ borderRadius: '4px' }}
                                variant="outlined"
                                size="small"
                                color="info"
                                // @ts-ignore
                                label={mapType[balanceHistory.tx_type] ?? balanceHistory.tx_type}
                              />
                            </p>
                            <span className="text-secondary">
                              {DateTime.fromISO(balanceHistory.created_at).toLocaleString(
                                DateTime.DATETIME_MED,
                              )}
                            </span>
                          </div>
                          <div className="box-price">
                            <p className="text-small mb-4">
                              <span
                                className={
                                  balanceHistory.amount.includes('-') ? 'text-red' : 'text-primary'
                                }
                              >
                                {!balanceHistory.amount.includes('-') ? '+' : '-'}
                              </span>{' '}
                              {balanceHistory.amount.replace('-', '')}
                              {' USDT'}
                            </p>
                            {/* <p className="text-end">
                              <span className="text-red">-</span>
                              {' '}
                              $950.50
                            </p> */}
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              {balanceHistory && balanceHistory.length === 0 && (
                <Typography>No transactions yet</Typography>
              )}
            </div>
            <div
              className={`tab-pane fade ${activeTab === 1 ? 'active show' : ''}`}
              id="market"
              role="tabpanel"
            >
              {officeHistory && officeHistory.length !== 0 && (
                <ul>
                  {officeHistory?.map((officeHistory, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={idx} className={idx !== 0 ? 'mt-8' : ''}>
                      <Link
                        to={`/transaction/office/${officeHistory.id}`}
                        className="coin-item style-1 gap-12 bg-surface"
                      >
                        <MonetizationOnIcon className="img" fontSize="small" />
                        <div className="content">
                          <div className="title">
                            <Box display="flex" alignItems="center" mb="4px">
                              {/* <p className="mb-4 text-large">Cash order</p> */}
                              <Chip
                                sx={{ borderRadius: '4px' }}
                                variant="outlined"
                                size="small"
                                color="warning"
                                label={mapStatus[officeHistory.status]}
                              />
                            </Box>
                            <span className="text-secondary">
                              {DateTime.fromISO(officeHistory.created_at).toLocaleString(
                                DateTime.DATETIME_MED,
                              )}
                            </span>
                          </div>
                          <div className="box-price">
                            <p className="text-small mb-4">
                              <span className="text-red">-</span> {officeHistory.sell_amount}{' '}
                              {officeHistory.sell_currency}
                            </p>
                            <p className="text-small">
                              <span className="text-primary">+</span> {officeHistory.buy_amount}{' '}
                              {officeHistory.buy_currency}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              {officeHistory && officeHistory.length === 0 && (
                <Typography>No transactions yet</Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
