import React, { FC } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';

type ListCardType = {
  title: string;
  description: string;
};

export const ListCard: FC<ListCardType> = ({ title, description }) => (
  <div className="content d-flex justify-content-between">
    <div className="flex-grow-1">
      <span className="text-small">{description}</span>
      <h5 className="mt-8 d-flex align-items-center gap-4 text-primary">
        {title}
      </h5>
    </div>
    <Box color="#7e8088">
      <ArrowForwardIosIcon fontSize="small" />
    </Box>
  </div>
);
