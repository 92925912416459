import { ClientUserApi } from 'api/user';
import { useQueryBuilder } from 'hooks';
import { noop } from 'lodash';
import { UserEntity } from 'model/user/user.types';
import { Query } from 'react-query';

export const useUsersQuery = (enabled = true, onError = noop, refetchInterval: number | false | ((data: UserEntity | undefined, query: Query<UserEntity, unknown, UserEntity, string>) => number | false) | undefined = false) => {
  const key = 'users';

  const props = useQueryBuilder({
    key,
    enabled,
    refetchInterval,
    retryDelay: 1000,
    method: async () => {
      const api = new ClientUserApi();
      const res = await api.getUser();

      return res;
    },
    onError,
  });

  return {
    ...props,
    key,
  };
};

export const useUsersDataQuery = (enabled = true) => {
  const key = 'users-data';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientUserApi();
      const res = await api.getUserData();

      return res;
    },
  });

  return {
    ...props,
    key,
  };
};

export const useUsersTRC20Query = (enabled = true) => {
  const key = 'users-trc20';

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientUserApi();
      const res = await api.getUserWalletTRC20();

      return res;
    },
  });

  return {
    ...props,
    key,
  };
};

export const useUsersCheckExistsQuery = (tag: string, enabled = true, onSuccess: () => void = noop) => {
  const key = `users-search-${tag}`;

  const props = useQueryBuilder({
    key,
    enabled,
    method: async () => {
      const api = new ClientUserApi();
      const res = await api.userSearch({ tag });

      return res;
    },
    onSuccess,
  });

  return {
    ...props,
    key,
  };
};
