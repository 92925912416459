import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { useParams } from 'react-router-dom';
import { useOfficeHistoryOneQuery } from 'queries/office';
import { OfficeOrderView } from 'components/OfficeOrderView';
import { noop } from 'lodash';
import { LoadingPage } from 'components/LoadingPage';

export const TransactionOfficeOnePage: FC = () => {
  const { id } = useParams();
  const { data, refetch, isLoading } = useOfficeHistoryOneQuery(Number(id), true, noop, 10000);

  return (
    <Layout header={<ModuleHeader title="Office order" withBack />}>
      <LoadingPage progress={isLoading && !data}>
        <OfficeOrderView transaction={data} refetch={refetch} />
      </LoadingPage>
    </Layout>
  );
};
