import React from 'react';
import { Link } from 'react-router-dom';
import { ListCard } from 'components/P2P/ListCard';
import { Box, Grid } from '@mui/material';
import cn from 'classnames';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { OrderList } from 'components/P2P/OrderList';
import { useUsersQuery } from 'queries/users';

export const P2PHome = () => {
  const { data: user } = useUsersQuery();

  return (
    <div className="pt-70 pb-16">
      <div className="tf-container">
        <Box className="mt-20">
          <h3 className="mt-16 mb-16">
            <div>Available balance: ${user?.free_balance}</div>
          </h3>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Link to="/p2p/buy" className="accent-box-v2 item-check-style3 bg-menuDark">
                <ListCard icon={<SouthIcon fontSize="large" />} title="Buy" />
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link to="/p2p/sell" className="accent-box-v2 item-check-style3 bg-menuDark">
                <ListCard icon={<NorthIcon fontSize="large" />} title="Sell" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link
                to="/p2p/ads"
                className={cn(['accent-box-v2', 'item-check-style3', 'bg-menuDark'], {
                  disabled: !user?.is_verified,
                })}
              >
                <ListCard
                  icon={<BusinessIcon fontSize="large" />}
                  title="My Ads"
                  description={
                    !user?.is_verified ? 'Verification required' : 'Manage ads and payment settings'
                  }
                />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link to="/p2p/express" className="accent-box-v2 item-check-style3 bg-menuDark">
                <ListCard
                  icon={<MonetizationOnOutlinedIcon fontSize="large" />}
                  title="Express"
                  description="Buy crypto in few clicks"
                />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <div className="accent-box-v2 bg-menuDark">
                <h3>My Orders</h3>

                <OrderList />
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
