import React from 'react';

export const TronChain = () => (
  <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_315_1156)">
      <path fillRule="evenodd" clipRule="evenodd" d="M34.3097 0.181692L41.9678 16.4719C41.9969 16.5325 42.0062 16.6009 41.9941 16.6672C41.9821 16.7335 41.9495 16.7941 41.901 16.8403L21.2183 36.911C21.1596 36.9681 21.0814 36.9999 21 36.9999C20.9186 36.9999 20.8403 36.9681 20.7816 36.911L0.0989418 16.8415C0.0504417 16.7954 0.0177956 16.7347 0.00577164 16.6684C-0.00624847 16.6022 0.00298309 16.5337 0.0321236 16.4731L7.6902 0.182945C7.71491 0.128236 7.75466 0.0818899 7.80469 0.0494467C7.85472 0.0170036 7.91291 -0.000164722 7.97232 -6.2673e-06H34.0251C34.0848 -0.000758325 34.1434 0.0159964 34.1939 0.0482368C34.2444 0.0804772 34.2846 0.126826 34.3097 0.181692Z" fill="#50AF95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.3428 18.1447C18.4913 18.156 19.2584 18.2024 20.9697 18.2024C22.3308 18.2024 23.2972 18.161 23.6362 18.1447C28.8963 17.9104 32.8225 16.9831 32.8225 15.8729C32.8225 14.7626 28.8963 13.8366 23.6362 13.5985V17.2212C23.2923 17.2463 22.3073 17.3052 20.9462 17.3052C19.3129 17.3052 18.495 17.2362 18.3477 17.2225V13.601C13.0988 13.8379 9.1813 14.7652 9.1813 15.8729C9.1813 16.9806 13.0976 17.9079 18.3477 18.1435L18.3428 18.1447ZM18.3428 13.2264V9.98461H11.0176V5.04117H30.9615V9.98461H23.6375V13.2251C29.5905 13.502 34.0673 14.6962 34.0673 16.1273C34.0673 17.5583 29.5905 18.7512 23.6375 19.0294V29.4175H18.344V19.0257C12.4047 18.7487 7.93528 17.5558 7.93528 16.126C7.93528 14.6962 12.4009 13.5033 18.344 13.2251L18.3428 13.2264Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_315_1156">
        <rect width="42" height="37" fill="white" transform="matrix(-1 0 0 1 42 0)" />
      </clipPath>
    </defs>
  </svg>
);
