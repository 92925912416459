import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { MasterCard } from 'components/Icons/MC';
import { Visa } from 'components/Icons/Visa';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { Card } from 'model/cards';
import { useCardsMutation } from 'queries/cards';
import useAlert from 'hooks/useAlert';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { useUsersQuery } from 'queries/users';

type CardItemProps = {
  card: Card['bin'];
};

const mapIcons = {
  MasterCard: <MasterCard />,
  Visa: <Visa />,
};

export const CreateOption: FC<CardItemProps> = ({ card }) => {
  const { alert } = useAlert();
  const navigate = useNavigate();
  const { data: currentUser } = useUsersQuery();
  const { createCard } = useCardsMutation();

  const onSuccess = () => {
    navigate('/success', {
      state: {
        message:
          'Card creating request completed successfully. Your card in progress, please wait until the card status changes to active',
        link: '/cards',
      },
    });
  };

  const onError = (error: AxiosError<DefaultError>) => {
    const errorMessage = error.response?.data?.detail;
    alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
  };

  const onConfirm = () => {
    const request = {
      bin: card.code,
      card_name: `matex-${currentUser?.tg_id}`,
      user: currentUser?.tg_id,
    };

    createCard.mutate(request, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  return (
    <Box borderRadius="10px" padding="20px" bgcolor="#393737">
      <Box display="flex" justifyContent="space-between" mb="10px">
        <Box display="flex" alignItems="center">
          <Typography className="text-white" sx={{ fontSize: '18px' }}>
            Simple virtual card
          </Typography>
        </Box>
        <Box width="60px" height="40px" display="flex" alignItems="center">
          {mapIcons[card.scheme]}
        </Box>
      </Box>
      <Box mb="20px">
        <Typography sx={{ fontSize: '12px' }}>
          [Note:] This is a virtual {card.scheme} (<strong>Bin: {card.code}</strong>). You can
          connect the card to ApplePay/Google Pay/ Paypal. This virtual card support 3DS
          confirmation.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className="text-white" sx={{ fontSize: '24px', fontWeight: 600 }}>
            25 USDT
          </Typography>
        </Box>
        <Box>
          <ConfirmDialog
            title="Confirmation"
            content="The card will be created, 25 usdt will be debited from your balance"
            onConfirm={onConfirm}
          >
            <Button>
              Apply now <ArrowForwardIosIcon sx={{ fontSize: '12px', ml: '6px' }} />
            </Button>
          </ConfirmDialog>
        </Box>
      </Box>
    </Box>
  );
};
