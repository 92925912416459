import React, { useState } from 'react';
import { Box, Grid, InputBase, MenuItem, Select, Typography } from '@mui/material';
import { OrderItem } from 'components/P2P/OrderItem';
import { useP2PCurrencyPairsQuery, useP2POffersListQuery } from 'queries/p2p';
import { P2PCurrencyPair } from 'model/p2p';
import { useUsersQuery } from 'queries/users';

export const Buy = () => {
  const [currentCurrencyPair, setCurrentCurrencyPair] = useState<string | number | null>(null);
  const { data } = useP2PCurrencyPairsQuery(true, (pairs: Array<P2PCurrencyPair>) => {
    if (pairs?.length) setCurrentCurrencyPair(pairs[0]?.id ?? null);
  });
  const { data: currentUser } = useUsersQuery();
  const { data: list } = useP2POffersListQuery(
    'sell',
    currentCurrencyPair as number,
    !!currentCurrencyPair,
  );

  return (
    <div className="pt-70 pb-16">
      <div className="tf-container">
        <Box className="mt-20">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box className="accent-box item-check-style3 bg-menuDark p-10-i">
                <div className="content d-flex justify-content-between">
                  <div className="content">
                    <div className="title">
                      <Typography
                        className="text-extra-large"
                        sx={{ color: '#fff', fontWeight: 500 }}
                      >
                        Method
                      </Typography>
                      <span className="mt-4 text-secondary text-small">ALL</span>
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="accent-box item-check-style3 bg-menuDark p-10-i">
                <div className="content d-flex justify-content-between">
                  <div className="content">
                    <div className="title">
                      <Typography
                        className="text-extra-large"
                        sx={{ color: '#fff', fontWeight: 500 }}
                      >
                        Currency
                      </Typography>
                      <Select
                        size="small"
                        className="text-primary text-small p-0"
                        sx={{ padding: '0', border: 'none', background: 'transparent' }}
                        input={<InputBase sx={{ padding: '0 !important', border: 'none' }} />}
                        IconComponent={undefined}
                        value={currentCurrencyPair}
                        onChange={(e) => {
                          setCurrentCurrencyPair(e?.target?.value ?? null);
                        }}
                      >
                        {data?.map((currencyPair) => (
                          <MenuItem key={currencyPair.id} value={currencyPair.id}>
                            {currencyPair.quote_currency}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="accent-box item-check-style3 bg-menuDark p-10-i">
                <div className="content d-flex justify-content-between">
                  <div className="content">
                    <div className="title">
                      <Typography
                        className="text-extra-large"
                        sx={{ color: '#fff', fontWeight: 500 }}
                      >
                        Crypto
                      </Typography>
                      <span className="mt-4 text-secondary text-small">USDT</span>
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {list?.map((item) => (
                <OrderItem
                  key={item.id}
                  order={item}
                  method="buy"
                  link={
                    currentUser?.tg_id !== item.owner?.tg_id
                      ? `/p2p/buy/${item.id}`
                      : `/p2p/ads/edit/${item.id}`
                  }
                />
              ))}
              {list && list.length === 0 && <Typography>No offers yet</Typography>}
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
