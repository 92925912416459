import { Box, Chip, Typography } from '@mui/material';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useP2POperationListQuery } from 'queries/p2p';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { middleElipsis } from 'utils';
import { useUsersQuery } from 'queries/users';
import { mapStatusText } from '../Operation';

// const TABS = [
//   {
//     key: 0,
//     title: 'Active',
//   },
//   {
//     key: 1,
//     title: 'Expired',
//   },
// ];

const activeTab = 0;

export const OrderList: FC = () => {
  // const [activeTab, setActiveTab] = useState(0);
  const { data: currentUser } = useUsersQuery();
  const { data: operations } = useP2POperationListQuery(activeTab === 0);
  // const { data: expiredOperations } = useP2POperationListExpiredQuery(activeTab === 1);

  // const handleChangeTabs = (newValue: number) => {
  //   setActiveTab(newValue);
  // };

  return (
    <>
      {/* <div className="tab-slide mt-10">
        <ul className="nav nav-tabs wallet-tabs" role="tablist">
          <li className="item-slide-effect" />
          {TABS.map((tab) => (
            <li
              key={tab.key}
              className={cn({
                'nav-item': true,
                active: activeTab === tab.key,
              })}
              role="presentation"
              onClick={() => handleChangeTabs(tab.key)}
            >
              <button
                type="button"
                className={cn({
                  'nav-link': true,
                  active: activeTab === tab.key,
                })}
                data-bs-toggle="tab"
                data-bs-target={`#${tab.title}`}
              >
                {tab.title}
              </button>
            </li>
          ))}
        </ul>
      </div> */}
      <div className="tab-content">
        <div
          className={`tab-pane fade ${activeTab === 0 ? 'active show' : ''}`}
          id="active"
          role="tabpanel"
        >
          {operations && operations.length !== 0 && (
            <ul>
              {operations?.map((operation, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={idx} className={idx !== 0 ? 'mt-8' : ''}>
                  <Link
                    to={`/p2p/operation/${operation.id}`}
                    className="coin-item style-1 gap-12 bg-menuDark mt-10"
                    style={{ backgroundColor: '#2B2B2C' }}
                  >
                    <Box className="content">
                      <div className="title">
                        <Typography
                          className="mb-4 text-extra-large"
                          sx={{ color: '#fff', fontWeight: 500 }}
                        >
                          {middleElipsis(operation.seller?.matextag, 14)}
                          <Chip
                            sx={{ borderRadius: '4px', ml: '6px' }}
                            variant="outlined"
                            size="small"
                            color="info"
                            label={operation.buyer?.tg_id === currentUser?.tg_id ? 'buy' : 'sell'}
                          />
                        </Typography>
                        <span className="text-secondary text-small">
                          {DateTime.fromISO(operation.created_at).toLocaleString(
                            DateTime.DATETIME_MED,
                          )}
                        </span>
                      </div>
                      <div className="box-price">
                        <Typography
                          className={cn(['text-extra-large', 'text-end'], {
                            'text-red': operation.status === 'cancelled',
                            'text-primary': operation.status !== 'cancelled',
                          })}
                          sx={{ fontWeight: 500 }}
                        >
                          {operation.base_amount} {operation.base_currency}
                        </Typography>
                        <Typography
                          className={cn(['text-small', 'text-end'], {
                            'text-red': operation.status === 'cancelled',
                            'text-primary': operation.status !== 'cancelled',
                          })}
                        >
                          {operation.status && mapStatusText[operation.status]}
                        </Typography>
                      </div>
                    </Box>
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {operations && operations.length === 0 && <Typography>No operations yet</Typography>}
        </div>
        {/* <div className={`tab-pane fade ${activeTab === 1 ? 'active show' : ''}`} id="expired" role="tabpanel">
          {expiredOperations && expiredOperations.length !== 0 && (
          <ul>
            {expiredOperations?.map((operation, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={idx} className={idx !== 0 ? 'mt-8' : ''}>
                <Link to={`/p2p/operation/${operation.id}`} className="coin-item style-1 gap-12 bg-menuDark mt-10" style={{ backgroundColor: '#2B2B2C' }}>
                  <Box className="content">
                    <div className="title">
                      <Typography className="mb-4 text-extra-large" sx={{ color: '#fff', fontWeight: 500 }}>{operation.seller?.matextag}</Typography>
                      <span className="text-secondary text-small">{DateTime.fromISO(operation.created_at).toLocaleString(DateTime.DATETIME_MED)}</span>
                    </div>
                    <div className="box-price">
                      <Typography
                        className={cn(['text-extra-large', 'text-end'], {
                          'text-red': operation.status === 'cancelled',
                          'text-primary': operation.status !== 'cancelled',
                        })}
                        sx={{ fontWeight: 500 }}
                      >
                        {operation.base_amount}
                        {' '}
                        {operation.base_currency}
                      </Typography>
                      <Typography className={cn(['text-small', 'text-end'], {
                        'text-red': operation.status === 'cancelled',
                        'text-primary': operation.status !== 'cancelled',
                      })}
                      >
                        {operation.status && mapStatusText[operation.status]}
                      </Typography>
                    </div>
                  </Box>
                </Link>
              </li>
            ))}
          </ul>
          )}
          {expiredOperations && expiredOperations.length === 0 && <Typography>No expired operations</Typography>}
        </div> */}
      </div>
    </>
  );
};
