import React, { FC } from 'react';
import { Layout } from 'components/Layout';
import { ModuleHeader } from 'components/Layout/Header/ModuleHeader';
import { Express } from 'components/P2P/Express';
import { useSearchParams } from 'react-router-dom';

const mapName: Record<string, string> = {
  sell: 'SuperSend',
  buy: 'Top-up',
};

export const P2PExpressPage: FC = () => {
  const [searchParams] = useSearchParams();
  const hasMethodUrl = !!searchParams?.get('method');

  return (
    <Layout
      header={
        <ModuleHeader
          title={`${
            !hasMethodUrl
              ? 'P2P Express'
              : mapName[searchParams?.get('method') ?? ''] ?? 'P2P Express'
          }`}
          withBack
        />
      }
    >
      <Express />
    </Layout>
  );
};
