import React, { FC, useState } from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { CardItem } from 'components/Cards/CardItem';
import GetAppIcon from '@mui/icons-material/GetApp';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseIcon from '@mui/icons-material/Pause';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Card, CardTransaction } from 'model/cards';
import { useCardsMutation, useUserCardDetailsQuery } from 'queries/cards';
import { useParams } from 'react-router-dom';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { Topup } from 'components/Cards/Topup';
import useAlert from 'hooks/useAlert';
import { AxiosError } from 'axios';
import { DefaultError } from 'lib/request/axios';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { PlayArrow } from '@mui/icons-material';

type GetOneProps = {
  card: Card;
  transactions: Array<CardTransaction>;
  refetch: () => void;
};

export const GetOne: FC<GetOneProps> = ({ card, transactions, refetch }) => {
  const { id } = useParams();
  const { alert } = useAlert();
  const [isDetails, setIsDetails] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { data: details, isLoading } = useUserCardDetailsQuery(id ?? '', isDetails);
  const { freezeCard, activateCard, closeCard } = useCardsMutation();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const onCardFreeze = () => {
    if (!id || card.status !== 'ACTIVE') {
      return;
    }

    const onSuccess = () => {
      alert('Card freezed successfully.', 'success');
      refetch();
    };

    const onError = (error: AxiosError<DefaultError>) => {
      const errorMessage = error.response?.data?.detail;
      alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
    };

    freezeCard.mutate(id, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  const onCardActivate = () => {
    if (!id || card.status !== 'INACTIVE') {
      return;
    }

    const onSuccess = () => {
      alert('Card activated successfully.', 'success');
      refetch();
    };

    const onError = (error: AxiosError<DefaultError>) => {
      const errorMessage = error.response?.data?.detail;
      alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
    };

    activateCard.mutate(id, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  const onCardClose = () => {
    if (!id || card.status === 'CLOSED') {
      return;
    }

    const onSuccess = () => {
      alert('Card closed successfully.', 'success');
      refetch();
    };

    const onError = (error: AxiosError<DefaultError>) => {
      const errorMessage = error.response?.data?.detail;
      alert(`${DEFAULT_ERROR_MESSAGE}${errorMessage ? `. Details: ${errorMessage}` : ''}`, 'error');
    };

    closeCard.mutate(id, {
      onSuccess,
      // @ts-ignore
      onError,
    });
  };

  return (
    <div className="pt-70 pb-90">
      <div className="tf-container">
        <div className="mt-4 text-center">
          <h4 className="mt-40 mb-40">Virtual card #{card.bin?.code}</h4>
        </div>
        {/* <div className="mt-4 accent-box-v2 bg-menuDark"> */}
        <CardItem card={!details ? card : { ...card, details }} />
        {/* </div> */}
        <Box mt="20px">
          <Grid container spacing={6} sx={{ justifyContent: 'center' }}>
            <Grid item>
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton
                  sx={{ width: '40px', height: '40px' }}
                  color="primary"
                  onClick={() => {
                    setDrawerOpen(true);
                  }}
                >
                  <GetAppIcon />
                </IconButton>
                <Typography color="primary" sx={{ fontSize: '12px', fontWeight: 500 }}>
                  Top-up
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="column" alignItems="center">
                <ConfirmDialog
                  title="Confirmation"
                  content="Are you sure want to see card details?"
                  onConfirm={() => {
                    setIsDetails(true);
                  }}
                >
                  <IconButton sx={{ width: '40px', height: '40px' }} color="primary">
                    {!isLoading ? (
                      <VisibilityIcon />
                    ) : (
                      <CircularProgress color="primary" size={24} />
                    )}
                  </IconButton>
                </ConfirmDialog>
                <Typography color="primary" sx={{ fontSize: '12px', fontWeight: 500 }}>
                  Details
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="column" alignItems="center">
                <ConfirmDialog
                  title="Confirmation"
                  content={`Are you sure want to ${card.status === 'ACTIVE' ? 'freeze' : ''}${
                    card.status === 'INACTIVE' ? 'activate' : ''
                  } your card?`}
                  onConfirm={() => {
                    if (card.status === 'ACTIVE') {
                      onCardFreeze();
                    } else if (card.status === 'INACTIVE') {
                      onCardActivate();
                    }
                  }}
                >
                  <IconButton
                    sx={{ width: '40px', height: '40px' }}
                    color="warning"
                    className={card.status === 'CLOSED' ? 'disabled' : ''}
                  >
                    {card.status === 'ACTIVE' && <PauseIcon color="warning" />}
                    {card.status === 'INACTIVE' && <PlayArrow color="warning" />}
                  </IconButton>
                </ConfirmDialog>
                <Typography
                  className={card.status === 'CLOSED' ? 'disabled' : ''}
                  color="warning"
                  sx={{ fontSize: '12px', fontWeight: 500, color: '#ed6c02' }}
                >
                  {card.status === 'ACTIVE' && 'Pause'}
                  {card.status === 'INACTIVE' && 'Activate'}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="column" alignItems="center">
                <ConfirmDialog
                  title="Confirmation"
                  content="Are you sure want to close your card?"
                  onConfirm={() => {
                    onCardClose();
                  }}
                >
                  <IconButton
                    sx={{ width: '40px', height: '40px' }}
                    color="error"
                    className={card.status === 'CLOSED' ? 'disabled' : ''}
                  >
                    <CancelIcon color="error" />
                  </IconButton>
                </ConfirmDialog>
                <Typography
                  className={card.status === 'CLOSED' ? 'disabled' : ''}
                  color="error"
                  sx={{ fontSize: '12px', fontWeight: 500 }}
                >
                  Close
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <div className="bg-menuDark tf-container mt-30" style={{ borderRadius: '8px' }}>
          <div className="tf-tab pt-12 mt-4">
            <h4 className="mb-10 mt-10">Transaction history</h4>
            <div className="tab-content pt-16 pb-16">
              <div className="tab-pane active show">
                {transactions && transactions.length !== 0 && (
                  <ul>
                    {transactions?.map((balanceHistory, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={idx} className={idx !== 0 ? 'mt-8' : ''}>
                        <div className="coin-item style-1 gap-12 bg-surface">
                          {/* <Link to={`/transaction/balance/${balanceHistory.id}?type=${balanceHistory.type}`} className="coin-item style-1 gap-12 bg-surface"> */}
                          {/* <img src="images/coin/coin-1.jpg" alt="img" className="img" /> */}
                          <MonetizationOnIcon className="img" fontSize="small" />
                          <div className="content">
                            <div className="title" style={{ width: '60%' }}>
                              <p
                                className="mb-4 text-large"
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                              >
                                {balanceHistory.merchant_name}
                              </p>
                              <span className="text-secondary">
                                {DateTime.fromISO(balanceHistory.date).toLocaleString(
                                  DateTime.DATETIME_SHORT,
                                )}
                              </span>
                            </div>
                            <div className="box-price">
                              <p className="text-small mb-4" style={{ textAlign: 'right' }}>
                                <span
                                  className={
                                    balanceHistory.amount?.toString()?.includes('-')
                                      ? 'text-red'
                                      : 'text-primary'
                                  }
                                >
                                  {balanceHistory.amount}{' '}
                                </span>
                                {balanceHistory.currency}
                              </p>
                              <p className="text-small" style={{ textAlign: 'right' }}>
                                <span>Fee:</span> {balanceHistory.charge} {balanceHistory.currency}
                              </p>
                              <Box textAlign="right" mt="4px">
                                <Chip
                                  sx={{ borderRadius: '4px' }}
                                  variant="outlined"
                                  size="small"
                                  color="info"
                                  // @ts-ignore
                                  label={balanceHistory.status}
                                />
                              </Box>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {transactions && transactions.length === 0 && (
              <Typography>No transactions yet</Typography>
            )}
          </div>
        </div>
      </div>

      <SwipeableDrawer
        anchor="right"
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="modal mRight">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
                <span
                  className="left"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  onClick={toggleDrawer(false)}
                >
                  <i className="icon-left-btn" />
                </span>
                <h3>Top-up</h3>
              </div>
              <div className="overflow-auto pt-70 pb-16">
                <div className="tf-container">
                  <Topup
                    onSubmitSuccess={() => {
                      setDrawerOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};
