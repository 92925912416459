/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';

export type DefaultError = { error?: string; detail?: string };

export class Request {
  private readonly client: AxiosInstance;

  constructor(config?: CreateAxiosDefaults) {
    const { headers, baseURL = process.env.REACT_APP_BASE_API_URL, ...props } = config || {};

    // const refreshAccessTokenFn = async (token: ApiTokenRefreshRequest) => {
    //   const api = new ClientApiTokenApi();
    //   const response = await api.refreshToken(token);
    //   return response;
    // };

    this.client = axios.create({
      baseURL,
      headers: {
        Accept: '*/*',
        ...headers as any,
      },
      withCredentials: true,
      ...props,
    });

    this.client.interceptors.request.use(
      (req) => {
        const accessToken = localStorage.getItem('access_token');

        if (!(req.headers as any)?.has('authorization') && !!accessToken) {
          (req.headers as any)?.set('Authorization', `Bearer ${accessToken}`);
        }

        return req;
      },
      async (error) => Promise.reject(error),
      // const originalRequest = error.config;
      // const errStatus = error.response.status as number;
      // const refreshToken = localStorage.getItem('refresh_token');
      // if (errStatus === 401 && !originalRequest._retry && refreshToken) {
      //   originalRequest._retry = true;
      //   await refreshAccessTokenFn({ refresh_token: refreshToken });
      //   return this.client(originalRequest);
      // }
    );

    // eslint-disable-next-line no-constructor-return
    return this;
  }

  get apiClient() {
    return this.client;
  }
}
